import { ArticleIconType, ArticleTag } from '@opoint/infomedia-storybook'
import { M360Article } from '../../opoint/articles/types'
import { BaseArticle } from '../../api/opoint.schemas'

export enum SentimentType {
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
  Notset = 'Notset',
  Balanced = 'Balanced',
  Factual = 'Factual',
}

export enum ManualSentiment {
  // @ts-expect-error: Muted so we could enable TS strict mode
  Notset = null,
  Neutral = 0,
  Negative = 1,
  Positive = 2,
}

export const manualToUpdatedSentimentMapping = {
  [ManualSentiment.Notset]: 'Reset',
  [ManualSentiment.Neutral]: SentimentType.Neutral,
  [ManualSentiment.Negative]: SentimentType.Negative,
  [ManualSentiment.Positive]: SentimentType.Positive,
} as const

export type UpdatedSentimentType =
  (typeof manualToUpdatedSentimentMapping)[keyof typeof manualToUpdatedSentimentMapping]

export const sentimentToManualMapping = {
  [SentimentType.Notset]: ManualSentiment.Notset,
  [SentimentType.Neutral]: ManualSentiment.Neutral,
  [SentimentType.Negative]: ManualSentiment.Negative,
  [SentimentType.Positive]: ManualSentiment.Positive,
} as const

export type CompanySentimentDetails = {
  auto: string
  id_group: number
  id_user: number
  manual: string
  name: string
}

export type SupportAccess = {
  report_issue?: boolean
  request_data?: boolean
  chat?: boolean
}

export enum ArticleMetadataType {
  Country = 'country',
  GlobalRank = 'rank_global',
  CountryRank = 'rank_country',
  PagePerVisit = 'page_per_visit',
  BounceRate = 'bounce_rate',
  Words = 'words',
  // TODO: Not implemented by the backend yet. Uncomment whenever it's been done.
  // | 'reach'
  ReadersSoFar = 'readers_so_far',
  NewReadersPerDay = 'new_readers_p_day',
  AverageReadership = 'average_readership',
  FinalReaders = 'final_readers',
  MonthlyVisits = 'mvisit',
  UniqueMobileVisits = 'unique_mobile_visit',
  UniqueDesktopVisits = 'unique_desktop_visit',
  UniqueDailyVisits = 'unique_daily_visit',
  UniqueVisits = 'unique_visit',
  TimeOnSite = 'time_on_site',
  EcbSentiment = 'ecb_sentiment',
}

export type ColorNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export type ArticleSimilarweb = {
  domain: string
  mvisit: number
  unique_mobile_visit: number
  unique_desktop_visit: number
  unique_daily_visit?: number
  unique_visit: number
  page_per_visit: number
  bounce_rate: number
  time_on_site: number
  category: string
  category_rank: number
  sanitize: number
  readership?: {
    P: number
    value: number
  }
  article_readership?: {
    P: number
    PValue?: number
    DeltaP: number
    DeltaPValue: number
    ExpectedP: number
    ExpectedPValue: number
    StdExpectedP: number
    StdExpectedPValue: number
    value: number
  }
}

export type TagWeight = -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

export type ColorbarPointType = {
  from: number
  to: number
  length: string // e.g. "2%"
  middle: string // e.g. "23%"
  color: ColorNumber
  quote?: {
    matches: boolean
    text?: string
  }
}

export type Colorbar = {
  maxlength: number
  minlength: number
  length: string // e.g. "52%"
  item: Array<ColorbarPointType>
}

export type ArticleImageProps = {
  caption?: {
    matches: boolean
    text: string
  }
  url?: string
}

export type ArticleMediaProps = {
  id: number
  localurl: string
  text: string
  url: string
  thumbnail_src: string
  thumbnail: string
}

export type ArticleScreenshot = {
  id_type: number
  width: number
  height: number
  text: string
}

export type ArticleMediaType = {
  text: ArticleIconType
  timemap: boolean
  clip: boolean
  hastext: boolean
  haslogo: boolean
  paywall: boolean
  fulltext: boolean
  duration?: number
}

export type ArticleCaption = {
  text: string
}

type FacebookMeta = {
  sm_fb_engagement_count: {
    value: string
  }
  sm_fb_reaction_count: {
    value: string
  }
  sm_fb_comment_count: {
    value: string
  }
  sm_fb_share_count: {
    value: string
  }
  sm_fb_stimestamp: {
    value: string
  }
}

type InstagramMeta = {
  sm_ig_engagement_count: {
    value: string
  }
  sm_ig_like_count: {
    value: string
  }
  sm_ig_comment_count: {
    value: string
  }
  sm_ig_stimestamp: {
    value: string
  }
}

type TwitterMeta = {
  sm_tw_engagement_count: {
    value: string
  }
  sm_tw_share_count: {
    value: string
  }
  sm_tw_retweet_count: {
    value: string
  }
  sm_tw_favorite_count: {
    value: string
  }
  sm_tw_stimestamp: {
    value: string
  }
}

export type SocialMetadata = FacebookMeta | InstagramMeta | TwitterMeta

export type SoMeAttachmentsMedia = {
  description?: string
  image?: string
  type: string
  url: string
}

export type SoMeAttachments = {
  quoted_tweet?: {
    attachments: { media?: { type: string; url: string }[] }
    author: { username: string; display_name: string; verified: boolean; profile_image: string }
    content_type: string
    link: string
    posted_time: string
    text: string
  }
  retweeted_user?: string
  reply_tweet?: {
    link: string
  }
  links?: Array<{
    site_name?: string
    description: string
    image: string
    title: string
    url: string
  }>
  media?: SoMeAttachmentsMedia[]
}

export type MediaMatch = {
  color: number
  matches?: string | string[]
  play_offset?: number
  quote: { match: boolean; text: string }
  seconds?: number
  snippet?: string
  text: string
  type: string
  video_id?: number
}

export type FirstSource = {
  id: number
  name: string
  sitename: string
  siteurl: string
  url: string
}

export type IdenticalDocument = {
  cnt: number
  document: M360Article[]
}

export type ArticleMatches = Array<{
  type: string
  color: number
  text: string
}>

export type SocialMeta = {
  attachments?: SoMeAttachments
  author: {
    display_name: string
    profile_image: string
    username: string
    verified: boolean
  }
  content_type: string
}

export type TagsInResponse = ArticleTag[]

export type AspectsInArticle = {
  [aspectId: number]: number[]
}

export type PDF_Parts = {
  pages: number
  args: string
  text: string
}

export type ArticleTranslation = {
  GTranslate_url?: string
  orig_language: { text: string }
  translated?: boolean
  translated_count?: number
}

export type ECBContent = {
  translated_header?: {
    matches: boolean
    language: string
    text: string
  }
  manual_summary?: {
    matches: boolean
    language: string
    text: string
  }
}

export enum ArticleSpecialStatsKey {
  Ranks = 'Ranks',
  Voices = 'Voices',
  Topics = 'Topics',
  Sentiment = 'Sentiment',
}

export type QueryIndexRequest = {
  articles: BaseArticle[]
  tokens: string[]
  id_user: number
}

export type QueryIndexResponse = {
  articles: BaseArticleWithMatches[]
  percentages: string[]
  tokens: string[]
  id_user: number
}

export type BaseArticleWithMatches = {
  id_site: number
  id_article: number
  matches?: number[]
}

export enum ArticleComparisonState {
  Unknown = 'unknown',
  Common = 'common',
  Added = 'added',
  Removed = 'removed',
}

export type ProfileBuilderArticle = M360Article & { match?: ArticleComparisonState }
