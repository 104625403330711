import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { concat, defer, from, of } from 'rxjs'
import { catchError, map, mapTo, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction, LogoutAction } from '../actions/auth'
import {
  ContactDeleteConfirmAction,
  ContactDeleteErrorAction,
  ContactDeleteSuccessAction,
  ContactDetailFetchSuccessAction,
  ContactDomainNotAllowedErrorAction,
  ContactFetchAction,
  ContactFetchFailureAction,
  ContactInactiveResetAction,
  ContactInactiveResetErrorAction,
  ContactInactiveResetSuccessAction,
  ContactSaveAction,
  ContactSaveErrorAction,
  ContactSaveSuccessAction,
  ContactSetActiveAction,
  ContactSetActiveFailureAction,
  ContactSetActiveSuccessAction,
  ContactsFetchSuccessAction,
  ContactUsedDeleteErrorAction,
  ErrorPayload,
  GroupDeleteConfirmAction,
  GroupDeleteErrorAction,
  GroupSaveAction,
  GroupSaveErrorAction,
  GroupSaveSuccessAction,
  GroupSetActiveAction,
  GroupSetActiveFailureAction,
  GroupSetActiveSuccessAction,
  GroupsFetchAction,
  GroupsFetchFailureAction,
  GroupsFetchSuccessAction,
  GroupUsedDeleteErrorAction,
} from '../actions/contacts'
import { Group } from '../components/types/contact'
import {
  deleteContact,
  deleteGroup,
  getContactDetail,
  getContacts,
  getGroupDetail,
  getGroups,
  saveContact,
  saveGroup,
} from '../opoint/contacts'
import { RootState } from '../reducers'
import { getEditedGroup } from '../selectors/contactSelectors'
import { getAllowedDomains, getOpointLocale } from '../selectors/settingsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchContactsOnLogIn = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    mapTo({ type: 'CONTACTS_FETCH' } as ContactFetchAction),
  )

const fetchGroupsOnLogIn = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    mapTo({ type: 'GROUPS_FETCH' } as GroupsFetchAction),
  )

const fetchContacts$ = defer(getContacts).pipe(
  map((contacts) => ({ type: 'CONTACTS_FETCH_SUCCESS', payload: contacts } as ContactsFetchSuccessAction)),
  catchError(logOutOnExpiredToken),
  catchError(serverIsDown),
  catchError(() => of<ContactFetchFailureAction>({ type: 'CONTACTS_FETCH_FAILURE' })),
)

export const fetchContacts = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ContactFetchAction>('CONTACTS_FETCH'),
    switchMap(() =>
      from(getContacts()).pipe(
        map((contacts) => ({ type: 'CONTACTS_FETCH_SUCCESS', payload: contacts } as ContactsFetchSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((err) =>
          err.status === 401
            ? of<LogoutAction>({ type: 'LOGOUT' })
            : of<ContactFetchFailureAction>({ type: 'CONTACTS_FETCH_FAILURE' }),
        ),
      ),
    ),
  )

export const fetchGroups = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, GroupsFetchAction>('GROUPS_FETCH'),
    switchMap(() =>
      from(getGroups()).pipe(
        map((groups) => ({ type: 'GROUPS_FETCH_SUCCESS', payload: groups } as GroupsFetchSuccessAction)),
        catchError((err) =>
          err.status === 401
            ? of<LogoutAction>({ type: 'LOGOUT' })
            : of<GroupsFetchFailureAction>({ type: 'GROUPS_FETCH_FAILURE' }),
        ),
      ),
    ),
  )

const saveContactsEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, ContactSaveAction>('CONTACT_SAVE'),
    switchMap(({ payload: contactFormValues }) => {
      const state = state$.value
      const allowedDomains = getAllowedDomains(state)
      const anyDomainRestrictions = !!allowedDomains
      const editedContactId = contactFormValues && contactFormValues.id

      if (anyDomainRestrictions) {
        const allowedDomainsRegExp = new RegExp(allowedDomains)

        if (!allowedDomainsRegExp.test(contactFormValues?.email)) {
          return of<ContactDomainNotAllowedErrorAction>({ type: 'CONTACT_DOMAIN_NOT_ALLOWED_ERROR' })
        }
      }

      const saveContact$ = from(saveContact(editedContactId, contactFormValues)).pipe(
        map((contact) => ({ type: 'CONTACT_SAVE_SUCCESS', payload: contact } as ContactSaveSuccessAction)),
      )

      return saveContact$.pipe(
        switchMap((saveContactAction) =>
          concat(
            of<ContactSaveSuccessAction>(saveContactAction),
            fetchContacts$,
            of<ContactDetailFetchSuccessAction>({
              type: 'CONTACT_DETAIL_FETCH_SUCCESS',
              payload: saveContactAction.payload,
            }),
            of<ContactSetActiveAction>({
              type: 'CONTACT_SET_ACTIVE',
              payload: saveContactAction.payload,
            }),
          ),
        ),
        catchError((e) => {
          let error: ErrorPayload
          try {
            error = {
              responseErrors: e.non_field_errors || [],
            }
          } catch (innerError) {
            error = {
              message: 'We were unable to save this contact',
            }
          }
          const actionType =
            error?.responseErrors?.non_field_errors?.[0] === 'Domain not allowed for user.'
              ? 'CONTACT_DOMAIN_NOT_ALLOWED_ERROR'
              : 'CONTACT_SAVE_ERROR'

          return of<ContactDomainNotAllowedErrorAction | ContactSaveErrorAction>({
            type: actionType,
            payload: error,
          })
        }),
      )
    }),
  )

const inactiveContactEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ContactInactiveResetAction>('CONTACT_INACTIVE_RESET'),
    switchMap(({ payload: contactFormValues }) => {
      contactFormValues.pause = null // reset pause value
      const contactId = contactFormValues && Number(contactFormValues.id)

      const resetInactiveContact$ = from(saveContact(contactId, contactFormValues)).pipe(
        map(
          (contact) =>
            ({
              type: 'CONTACT_INACTIVE_RESET_SUCCESS',
              payload: { contact },
            } as ContactInactiveResetSuccessAction),
        ),
      )

      return resetInactiveContact$.pipe(
        switchMap((resetInactiveContactAction) =>
          concat(
            of(resetInactiveContactAction),
            fetchContacts$,
            of<ContactDetailFetchSuccessAction>({
              type: 'CONTACT_DETAIL_FETCH_SUCCESS',
              payload: resetInactiveContactAction.payload.contact,
            }),
            of<ContactSetActiveAction>({
              type: 'CONTACT_SET_ACTIVE',
              payload: resetInactiveContactAction.payload.contact,
            }),
          ),
        ),
        catchError(() => {
          const error = {
            message: 'We were unable to reset pause of alerts for this contact',
          }

          return of<ContactInactiveResetErrorAction>({ type: 'CONTACT_INACTIVE_RESET_ERROR', payload: error })
        }),
      )
    }),
  )

const deleteContactEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ContactDeleteConfirmAction>('CONTACT_DELETE_CONFIRM'),
    switchMap(({ payload }) => {
      const { flag, contact: contactFormValues } = payload || {}
      const state = state$.value
      const editedContactId = Number(contactFormValues.id)
      const deleteContact$ = from(deleteContact(editedContactId, getOpointLocale(state), flag)).pipe(
        map(() => ({ type: 'CONTACT_DELETE_SUCCESS', payload: editedContactId } as ContactDeleteSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((e) => {
          //TODO: what is e? we need to figure out how to properly type CONTACT_USED_DELETE_ERROR action
          if (!e.message) {
            e.message = 'We were unable to delete this contact'
          }

          return e.data && e.data.alerts.length !== 0
            ? of<ContactUsedDeleteErrorAction>({ type: 'CONTACT_USED_DELETE_ERROR', payload: e })
            : of<ContactDeleteErrorAction>({ type: 'CONTACT_DELETE_ERROR' })
        }),
      )

      return deleteContact$.pipe(switchMap((deleteContactAction) => of(deleteContactAction)))
    }),
  )

const setActiveContactEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ContactSetActiveAction>('CONTACT_SET_ACTIVE'),
    switchMap(({ payload: { id } }) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const fetchContact$ = from(getContactDetail(id))

      return fetchContact$.pipe(
        switchMap((contact) =>
          concat(
            of<ContactDetailFetchSuccessAction>({ type: 'CONTACT_DETAIL_FETCH_SUCCESS', payload: contact }),
            of<ContactSetActiveSuccessAction>({ type: 'CONTACT_SET_ACTIVE_SUCCESS', payload: contact }),
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<ContactSetActiveFailureAction>({ type: 'CONTACT_SET_ACTIVE_FAILURE' })),
      )
    }),
  )

const setActiveGroupEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, GroupSetActiveAction>('GROUP_SET_ACTIVE'),
    switchMap(({ payload: { id } }) => {
      const fetchGroup$ = from(getGroupDetail(id))

      return fetchGroup$.pipe(
        switchMap((group: Group) =>
          concat(of<GroupSetActiveSuccessAction>({ type: 'GROUP_SET_ACTIVE_SUCCESS', payload: group })),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<GroupSetActiveFailureAction>({ type: 'GROUP_SET_ACTIVE_FAILURE' })),
      )
    }),
  )

const saveGroupEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, GroupSaveAction>('GROUP_SAVE'),
    switchMap(({ payload: data }) => {
      const fetchGroups$ = defer(getGroups).pipe(
        map((groups) => ({ type: 'GROUPS_FETCH_SUCCESS', payload: groups })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<GroupsFetchFailureAction>({ type: 'GROUPS_FETCH_FAILURE' })),
      )
      const saveGroup$ = from(saveGroup(data)).pipe(
        map((group) => ({ type: 'GROUP_SAVE_SUCCESS', payload: { group } } as GroupSaveSuccessAction)),
      )

      return saveGroup$.pipe(
        switchMap((saveGroupAction) =>
          concat(
            of(saveGroupAction),
            fetchGroups$,
            of<GroupSetActiveAction>({ type: 'GROUP_SET_ACTIVE', payload: saveGroupAction.payload.group }),
          ),
        ),
        catchError((e) => {
          let error
          try {
            error = {
              responseErrors: e.non_field_errors || [],
            }
          } catch (innerError) {
            error = {
              message: 'We were unable to save this group',
            }
          }

          return of<GroupSaveErrorAction>({ type: 'GROUP_SAVE_ERROR', payload: error })
        }),
      )
    }),
  )

const deleteGroupEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, GroupDeleteConfirmAction>('GROUP_DELETE_CONFIRM'),
    switchMap(() => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const editedGroup: Group = getEditedGroup(state$.value)
      const fetchGroups$ = defer(getGroups).pipe(
        map((groups) => ({ type: 'GROUPS_FETCH_SUCCESS', payload: groups } as GroupsFetchSuccessAction)),
        catchError(() => of<GroupsFetchFailureAction>({ type: 'GROUPS_FETCH_FAILURE' })),
      )

      const deleteGroup$ = from(deleteGroup(editedGroup.id, getOpointLocale(state$.value))).pipe(
        map(() => ({ type: 'GROUP_DELETE_SUCCESS', payload: editedGroup.id })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((e) => {
          let message
          try {
            message = JSON.parse(e.originalEvent.target.response)
          } catch (innerError) {
            message = {
              message: 'We were unable to delete this group',
            }
          }

          return message.data && message.data.alerts.length !== 0
            ? of<GroupUsedDeleteErrorAction>({ type: 'GROUP_USED_DELETE_ERROR', payload: message })
            : of<GroupDeleteErrorAction>({ type: 'GROUP_DELETE_ERROR' })
        }),
      )

      return deleteGroup$.pipe(switchMap((deleteGroupAction) => concat(of(deleteGroupAction), fetchGroups$)))
    }),
  )

export default [
  deleteContactEpic,
  deleteGroupEpic,
  fetchContacts,
  fetchContactsOnLogIn,
  fetchGroups,
  fetchGroupsOnLogIn,
  inactiveContactEpic,
  saveContactsEpic,
  saveGroupEpic,
  setActiveContactEpic,
  setActiveGroupEpic,
]
