import { captureMessage } from '@sentry/react'
import { Reducer } from 'redux'

import {
  always,
  ascend,
  assoc,
  assocPath,
  clone,
  compose,
  descend,
  dissoc,
  dissocPath,
  evolve,
  filter,
  flatten,
  indexBy,
  indexOf,
  isEmpty,
  lensPath,
  map as mapR,
  merge,
  over,
  pick,
  prop,
  propEq,
  sortWith,
  toPairs,
  values,
  when,
  ifElse,
  append,
  without,
} from 'ramda'
import { EXPORT_TYPES } from '../components/constants/export'
import { ExportFileFormValues } from '../components/statistics/export/Export'
import { getWidgetSelection } from '../components/statistics/statisticsControllers'
import { ExportObject } from '../components/types/statistics'
import { handleSelectedBaskets } from '../helpers/listing'
import { EXACT_MODE, FUZZY_MODE, NON_FILTERABLE_GRAPH_TYPES } from '../opoint/common/constants'
import type { Action, StatisticAspect } from '../opoint/flow'
import { StatisticsView } from '../opoint/flow'
import { defaultWidgetTypes } from '../opoint/statistics'
import { DEFAULT_ASPECT_GROUPS_TO_COMPUTE, SPECIAL_ASPECTS_IDS } from '../opoint/statistics/aspects'
import { TAG_TYPES } from '../opoint/tags'
import { M360Article } from '../opoint/articles/types'

type ReducedDocument = {
  adprice?: number
  aspects: any
  engagements?: number
  id_article: number
  id_site: number
  reach?: number
  circulation?: number
  unix_timestamp: number
}

export type StatisticsState = {
  aspects: Array<StatisticAspect>
  documents: Array<any>
  // eslint-disable-next-line @typescript-eslint/ban-types
  filters: any | {}
  countBy: 'count' | 'circulation' | 'reach' | 'eac' | 'engagements'
  loading: boolean
  computedAspectGroup: number
  aspectsRequested: any
  list: Array<StatisticsView>
  activeStatView: any | null
  showFilteredArticles: boolean
  filteredArticles: Array<any>
  changedAspectsType: any
  changedAspectsCountBy: any
  exportTrigger: EXPORT_TYPES.Pdf | EXPORT_TYPES.PowerPoint | EXPORT_TYPES.Excel | null
  statisticsComparisonOpen: boolean
  comparedDocuments: Array<any>
  previousPeriod: { prevRangeEnd: any; prevRangeStart: any }
  previousAspects: Array<any>
  previousPeriodDatePicker: boolean
  previousPeriodDates: {
    startDate: number
    endDate: number
  }
  comparePeriod: string
  //Initial states to compare for save actionbar button to show
  initialAspects: Array<StatisticAspect>
  initialAspectsType: { [key: string]: string }
  initialAspectsCountBy: { [key: string]: string }
  correctStatisticsDates: any
  openRenamingPopup: boolean
  exportLoading: boolean
  regularStatsFetch: boolean
  unsavedAspectsType: any
  articles: M360Article[]
  loadingIsTakingTooLong: boolean
  compareStatsLoading: boolean
  exportId: number
  statisticsExportIsTakingTooLong: boolean
  exportFormValues: ExportFileFormValues | null
  exportObject: ExportObject | null
  checkedArticles: Array<{
    id_site: number
    id_article: number
  }>
  selectedArticlesFetch: boolean
}

export const initialState: StatisticsState = {
  aspects: [],
  documents: [],
  filters: {},
  countBy: 'count',
  loading: false,
  exportLoading: false,
  comparedDocuments: [],
  previousPeriod: { prevRangeEnd: {}, prevRangeStart: {} },
  previousAspects: [],
  previousPeriodDatePicker: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  previousPeriodDates: null,
  comparePeriod: 'Previous period',
  compareStatsLoading: false,
  computedAspectGroup: DEFAULT_ASPECT_GROUPS_TO_COMPUTE,
  aspectsRequested: [],
  list: [],
  activeStatView: null,
  showFilteredArticles: false,
  filteredArticles: [],
  changedAspectsType: {},
  changedAspectsCountBy: {},
  exportTrigger: null,
  statisticsComparisonOpen: false,
  initialAspects: [],
  initialAspectsCountBy: {},
  initialAspectsType: {},
  correctStatisticsDates: {},
  openRenamingPopup: false,
  regularStatsFetch: false,
  //Used to persist widgets types for unsaved views
  unsavedAspectsType: {},
  articles: [],
  loadingIsTakingTooLong: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  exportId: null,
  statisticsExportIsTakingTooLong: false,
  exportObject: null,
  checkedArticles: [],
  selectedArticlesFetch: false,
}

// Used to store current period aspects.
let currentAspects = null

/**
 * convert array of aspectId-partId pairs from api format to object
 * example: [[111, 1], [222, 2], [111, 4]] -> {111: [1,4], 222: [2]}
 * @param docsAspects
 * @param aspects
 */
const aspectsById = (docsAspects, aspects, compareMode) => {
  const aspectsById = {}
  aspects?.forEach(({ id }) => {
    // initiate empty arrays first
    aspectsById[id] = []
  })
  docsAspects?.forEach(([aspId, partId]) => {
    if (!aspectsById[aspId]) {
      captureMessage('Bad API response. Aspect %i not in aspectset', {
        level: 'info',
        extra: { aspectId: aspId },
      })
      aspectsById[aspId] = []
    }
    aspectsById[aspId].push(
      compareMode
        ? aspects.filter((a) => a.id === aspId)[0]?.aspectpart.filter((p) => p.id === partId)[0]?.name[0]
        : partId,
    ) // add to array
  })

  return aspectsById
}

/**
 * Return all names of aspectPart with given id in given aspect
 * @param aspect
 * @param partId
 */
const namesOfPartById = (aspect, partId, compareMode) => {
  const aspectPart = compareMode
    ? aspect?.aspectpart?.find(({ name }) => name[0] === partId)
    : aspect?.aspectpart?.find(({ id }) => +id === +partId)

  if (!aspectPart) {
    captureMessage('Bad API response. Part %i of aspect %i not found.', {
      level: 'info',
      extra: { partId, aspectId: aspect?.id },
    })

    return []
  }

  return aspectPart.name
}

/**
 * Replace multiple aspect part with one aspect parts with same name set
 * eg. if doc has aspect 13 with parts [802, 803] and
 * those parts have names ["B:279626"] and ["B:279625"]
 * and there also exists part 804 which has names ["B:279626", "B:279625"]
 * then replace parts with [804]
 * @param aspectsById
 * @param aspects
 * @returns {*}
 */

const mergeMultipleAspectParts = (aspectsById, aspects, compareMode) => {
  toPairs(aspectsById)?.forEach(([aspId, aspectPartIds]) => {
    if (aspectPartIds.length > 1) {
      const aspect = aspects.find(({ id }) => +id === +aspId)
      const isSameSet = (namesA, namesB) =>
        namesA.length === namesB.length && namesA.every((name) => namesB.includes(name))
      const currentAspectPartNames = flatten(
        aspectPartIds?.map((partId) => {
          const names = namesOfPartById(aspect, partId, compareMode)
          if (names.length > 1) {
            captureMessage('Cannot merge Aspect with multiple names', { level: 'info' })
            throw Error('Cannot merge Aspect with multiple names')
          }

          return names
        }),
      )

      // if some unused aspectPart containing all of aspectParts names exist, use it instead
      const equivalentPart = aspect?.aspectpart?.find(({ name }) => isSameSet(name, currentAspectPartNames))

      if (equivalentPart) {
        aspectsById[aspId] = [equivalentPart.id]
      }
    }
  })

  return aspectsById
}

/**
 * Removing redundant data from documents.
 * @param documents
 * @returns Returns an array of documents with a reduced amount of data.
 */
const reduceDocumentsData = (documents): ReducedDocument => {
  return documents?.map((d) => ({
    adprice: d.adprice,
    aspects: d.aspects,
    engagements: d.engagements,
    id_article: d.id_article,
    id_site: d.id_site,
    reach: d.reach,
    circulation: d.circulation,
    unix_timestamp: d.unix_timestamp,
  }))
}

/**
 * Changing the names of the Time widgets (TW) aspectparts, so that we can compare the two periods better.
 * Barbing off the day and month from the name.
 * @param aspects
 * @param compareMode Should only trigger in compare mode
 * @returns Returns aspects, but with changed names of TW's aspectparts
 */
const timeWidgetAspectpartsNameChange = (aspects, compareMode) => {
  if (compareMode && aspects) {
    return aspects
      .filter((aspect) => aspect.id === 39)[0]
      ?.aspectpart?.map((part) => {
        part.name = [part.name?.[0]?.split(' ')?.[0] ?? '']
      }, aspects)
  }
}

/**
 * This reducer controls how we retrieve statistic results from Opoint's backend.
 */
// @ts-expect-error: Muted so we could enable TS strict mode
const statisticsReducer: Reducer<StatisticsState, any> = (
  state: StatisticsState = initialState,
  { type, payload }: Action<any>,
) => {
  switch (type) {
    case 'LOGOUT': {
      return initialState
    }

    case 'STATISTICS_VIEWS_OPEN':
    case 'FETCH_STATISTICS_CONFIRMED': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      return compose(
        assoc('loading', true),
        assoc('compareStatsLoading', true),
        assoc('documents', []),
        assoc('comparedDocuments', []),
        assoc('selectedArticlesFetch', false),
      )(state)
    }

    case 'FETCH_STATISTICS_COMPARE': {
      return compose(assoc('compareStatsLoading', true))(state)
    }

    case 'STATISTICS_CLEAN_UP': {
      return {
        ...state,
        aspects: state.statisticsComparisonOpen ? state.aspects : [],
        aspectsRequested: [],
        filteredArticles: [],
        filters: {},
        loading: true,
        showFilteredArticles: false,
      }
    }

    case 'STATISTICS_SHOW_FILTERED': {
      return assoc('showFilteredArticles', true)(state)
    }

    case 'STATISTICS_HIDE_FILTERED': {
      return assoc('showFilteredArticles', false)(state)
    }

    case 'FETCH_STATISTICS_COMPARE_SUCCESS': {
      const { isStatView, preserveAspects, compareMode, timePeriod, response } = payload

      const previousAspects = response?.searchresult?.aspectset?.aspect
      let documents = response?.searchresult?.document

      let aspects = previousAspects

      // In rare cases, the amount of aspects from current and previous period, will be different from each other.
      // This will cause the app to crash completely or make it load indefinite.
      // If current aspects has a higher amount, we're filtering the missing aspect from the current period, and adding it to the previous period.
      // This will prevent the app from crashing.
      if (currentAspects) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        if (currentAspects.length > previousAspects.length) {
          // @ts-expect-error: Muted so we could enable TS strict mode
          const missingAspect = currentAspects.filter(
            (cAspect) => !previousAspects.find((pAspect) => cAspect.id === pAspect.id),
          )
          missingAspect?.map((aspect) => {
            aspects.push(aspect)
          })
        }
      }

      const filteredDocs = documents?.filter((document) => document.remove !== 'remove')

      documents = filteredDocs

      documents = reduceDocumentsData(documents)

      // process aspectparts for Time widget
      timeWidgetAspectpartsNameChange(aspects, compareMode)

      // process documents:
      documents = (documents || [])?.map((doc) => ({
        ...doc,
        // convert timestamp to Date
        date: new Date(doc.unix_timestamp * 1000),
        // normalize fields by which can articles be summed
        count: 1,
        reach: doc.reach || 0,
        eac: doc.adprice || 0,
        engagements: doc.engagements || 0,
        circulation: doc.circulation || 0,
        // append aspect directly to doc under its id
        ...mergeMultipleAspectParts(aspectsById(doc.aspects, aspects, compareMode), aspects, compareMode),
      }))

      // process aspects:
      // @ts-expect-error: Muted so we could enable TS strict mode
      const toObjById = indexBy(prop('id'))
      const renameProp = (from, to) => (a) => {
        a[to] = a[from]
        delete a[from]

        return a
      }

      const modifyAspects = mapR(
        compose(
          (a) => (isStatView ? a : assoc('selected', isAspectComputed(a) && isSepEnough(a))(a)),
          // @ts-expect-error: Muted so we could enable TS strict mode
          (a) => assoc('overlap', Math.abs(a.combo || 0))(a),
          // @ts-expect-error: Muted so we could enable TS strict mode
          (a) => assoc('overlapMode', Math.sign(a.combo) || FUZZY_MODE)(a),
          assoc('dirty', false),
          evolve({
            // modify aspectpart
            // @ts-expect-error: Muted so we could enable TS strict mode
            aspectpart: compose(toObjById, mapR(renameProp('name', 'names'))),
          }),
        ),
      )

      const sortAspects = sortWith([
        // @ts-expect-error: Muted so we could enable TS strict mode
        descend(prop('sep')), // ...Separation Coefficient (greater is better)
        // @ts-expect-error: Muted so we could enable TS strict mode
        ascend(prop('group')), // ...by group (lower is better)
        // @ts-expect-error: Muted so we could enable TS strict mode
        ascend(prop('label')), // ...by label alphabetically
      ])

      // this is here only to prevent historically wrongly saved charts from throwing error

      const deselectUncomputedSavedAspects = (aspects) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        isStatView ? mapR((a) => assoc('selected', isAspectComputed(a) && a.selected)(a))(aspects) : aspects

      // @ts-expect-error: Muted so we could enable TS strict mode
      aspects = compose(deselectUncomputedSavedAspects, sortAspects, modifyAspects)(aspects)

      if (isStatView) {
        const widgetSelection = getWidgetSelection() || '{}'
        const { selectedAspectIds } = JSON.parse(widgetSelection)
        aspects?.forEach((aspect) => {
          if (selectedAspectIds?.includes(aspect.id)) {
            aspect.selected = true
          }
        })
      }

      const preservePreviousProps = (newAspects) => (oldAspects) =>
        newAspects?.map((aspect) => {
          const oldAspect = oldAspects.find(({ id }) => id === aspect.id)

          return oldAspect
            ? {
                ...aspect,
                tagLikeEntities: oldAspect.tagLikeEntities,
                selected: oldAspect.selected || wasRequested(state, oldAspect),
              }
            : aspect
        })

      const isTimePeriodEmpty = isEmpty(timePeriod)

      const realStartRange =
        (isTimePeriodEmpty ? filteredDocs[filteredDocs?.length - 1]?.unix_timestamp : timePeriod?.oldest) * 1000
      const realEndDate = (isTimePeriodEmpty ? filteredDocs[0]?.unix_timestamp : timePeriod?.newest) * 1000

      return evolve({
        comparedDocuments: always(documents),
        previousAspects: preserveAspects ? preservePreviousProps(aspects) : always(aspects),
        compareStatsLoading: always(false),
        previousPeriod: always({
          prevRangeEnd: realEndDate,
          prevRangeStart: realStartRange,
        }),
        loadingIsTakingTooLong: always(false),
      })(state)
    }

    case 'FETCH_STATISTICS_SUCCESS': {
      let { preserveAspects /* eslint-disable-line prefer-const */ } = payload

      let aspects = payload?.response?.searchresult?.aspectset?.aspect
      let documents = payload?.response?.searchresult?.document

      const {
        isStatView,
        regularStatsFetch,
        compareMode,
        timePeriod,
        changedAspectsType,
        changedAspectsCountBy,
        articles,
        selectedArticlesFetch,
      } = payload

      // Storing aspects, in case previous periods needs it.
      currentAspects = aspects

      documents = reduceDocumentsData(documents)

      // process aspectparts for Time widget
      timeWidgetAspectpartsNameChange(aspects, compareMode)

      // process documents:
      documents = (documents || [])?.map((doc) => ({
        ...doc,
        // convert timestamp to Date
        date: new Date(doc.unix_timestamp * 1000),
        // normalize fields by which can articles be summed
        count: 1,
        reach: doc.reach || 0,
        eac: doc.adprice || 0, // TODO: We might receive this prop, renamed from "adprice" to "eac". When that's done, change this.
        engagements: doc.engagements || 0,
        circulation: doc.circulation || 0,
        // append aspect directly to doc under its id
        ...mergeMultipleAspectParts(aspectsById(doc.aspects, aspects, compareMode), aspects, compareMode),
      }))

      // process aspects:
      // @ts-expect-error: Muted so we could enable TS strict mode
      const toObjById = indexBy(prop('id'))
      const renameProp = (from, to) => (a) => {
        a[to] = a[from]
        delete a[from]

        return a
      }

      const modifyAspects = mapR(
        compose(
          (a) => (isStatView ? a : assoc('selected', isAspectComputed(a) && isSepEnough(a))(a)),
          // @ts-expect-error: Muted so we could enable TS strict mode
          (a) => assoc('overlap', Math.abs(a.combo || 0))(a),
          // @ts-expect-error: Muted so we could enable TS strict mode
          (a) => assoc('overlapMode', Math.sign(a.combo) || FUZZY_MODE)(a),
          assoc('dirty', false),
          evolve({
            // modify aspectpart
            // @ts-expect-error: Muted so we could enable TS strict mode
            aspectpart: compose(toObjById, mapR(renameProp('name', 'names'))),
          }),
        ),
      )

      const sortAspects = sortWith([
        // @ts-expect-error: Muted so we could enable TS strict mode
        descend(prop('sep')), // ...Separation Coefficient (greater is better)
        // @ts-expect-error: Muted so we could enable TS strict mode
        ascend(prop('group')), // ...by group (lower is better)
        // @ts-expect-error: Muted so we could enable TS strict mode
        ascend(prop('label')), // ...by label alphabetically
      ])

      const computedAspectGroup = aspects?.reduce(
        (group, aspect) =>
          /* eslint-disable-next-line no-bitwise */
          group | (isAspectComputed(aspect) ? aspect.group : 0),
        0,
      )

      // this is here only to prevent historically wrongly saved charts from throwing error

      const deselectUncomputedSavedAspects = (aspects) =>
        // @ts-expect-error: Muted so we could enable TS strict mode
        isStatView ? mapR((a) => assoc('selected', isAspectComputed(a) && a.selected)(a))(aspects) : aspects

      // @ts-expect-error: Muted so we could enable TS strict mode
      aspects = compose(deselectUncomputedSavedAspects, sortAspects, modifyAspects)(aspects)

      // Default active widgets = Speaker, Media channel, Site name
      if (!isStatView) {
        const widgetSelection = getWidgetSelection() || '{}'
        const parsedWidgetSelection = JSON.parse(widgetSelection)

        aspects = aspects?.map((aspect) => {
          if ([4, 19, 10].includes(aspect.id) && isEmpty(parsedWidgetSelection)) {
            return { ...aspect, selected: true }
          } else {
            return { ...aspect, selected: false }
          }
        })

        aspects?.forEach((aspect) => {
          if (parsedWidgetSelection?.selectedAspectIds?.includes(aspect.id)) {
            aspect.selected = true
          }
        })
      }

      const preservePreviousProps = (newAspects) => (oldAspects) =>
        newAspects?.map((aspect) => {
          const oldAspect = oldAspects.find(({ id }) => id === aspect.id)

          return oldAspect
            ? {
                ...aspect,
                tagLikeEntities: oldAspect.tagLikeEntities,
                selected: oldAspect.selected || wasRequested(state, oldAspect),
              }
            : aspect
        })

      const rangeStart = timePeriod?.oldest ?? payload.response.searchresult.rangeStart / 1000
      const rangeEnd = timePeriod?.newest ?? payload.response.searchresult.rangeEnd / 1000

      // Sorting documents by unix and Filtering away articles that's not within range.
      let filteredDocs: ReducedDocument[] = []
      if (documents) {
        filteredDocs = documents
          .sort((a, b) => b.unix_timestamp - a.unix_timestamp)
          .filter((document) => document.unix_timestamp >= rangeStart && document.unix_timestamp <= rangeEnd)
      }

      const isTimePeriodEmpty = isEmpty(timePeriod)

      const correctStartDate =
        (isTimePeriodEmpty ? filteredDocs[filteredDocs?.length - 1]?.unix_timestamp : rangeStart) * 1000
      const correctEndDate = (isTimePeriodEmpty ? filteredDocs[0]?.unix_timestamp : rangeEnd) * 1000
      const emptyAspects = state.aspects.length === 0

      return evolve({
        aspects: preserveAspects ? preservePreviousProps(aspects) : always(aspects),
        initialAspects: preserveAspects ? preservePreviousProps(aspects) : always(aspects),
        documents: always(filteredDocs),
        changedAspectsType: emptyAspects ? always(changedAspectsType || {}) : state.changedAspectsType,
        initialAspectsType: always(changedAspectsType || {}),
        changedAspectsCountBy: always(changedAspectsCountBy || {}),
        initialAspectsCountBy: always(changedAspectsCountBy || {}),
        /* eslint-disable-next-line no-bitwise */
        computedAspectGroup: (group) => group | computedAspectGroup,
        correctStatisticsDates: always({ correctStartDate, correctEndDate }),
        loading: always(false),
        loadingIsTakingTooLong: always(false),
        regularStatsFetch: always(regularStatsFetch),
        articles: always(articles),
        checkedArticles: always([]), // Reset checked articles, after first fetch.
        selectedArticlesFetch: always(selectedArticlesFetch),
      })(state)
    }

    case 'FETCH_STATISTICS_EMPTY_SEARCH': {
      return { ...state, loading: false, compareStatsLoading: false }
    }

    case 'FETCH_STATISTICS_FAILURE': {
      return compose(assoc('loading', false), assoc('loadingIsTakingTooLong', false))(state)
    }

    case 'STATISTICS_ASPECT_OVERLAP_CHANGE': {
      const { aspectId, overlap } = payload
      // @ts-expect-error: Muted so we could enable TS strict mode
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))

      return compose(
        assocPath(['aspects', aspectPos, 'dirty'], true),
        assocPath(['aspects', aspectPos, 'overlap'], overlap),
      )(state)
    }

    case 'STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE': {
      const { aspectId } = payload
      // @ts-expect-error: Muted so we could enable TS strict mode
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))
      // toggle between exact and fuzzy mode
      const toggleMode = (mode) => (mode === EXACT_MODE ? FUZZY_MODE : EXACT_MODE)

      return compose(
        assocPath(['aspects', aspectPos, 'dirty'], true),
        over(lensPath(['aspects', aspectPos, 'overlapMode']), toggleMode),
      )(state)
    }

    case 'STATISTICS_ASPECT_TOGGLE': {
      const { aspectId, selected } = payload

      return evolve({
        // @ts-expect-error: Muted so we could enable TS strict mode
        aspectsRequested: ifElse(() => selected, append(aspectId), without([aspectId])),
        aspects: mapR(when(propEq('id', aspectId), assoc('selected', selected))),
        previousAspects: mapR(when(propEq('id', aspectId), assoc('selected', selected))),
        filters: dissoc(aspectId),
      })(state)
    }

    case 'STATISTICS_CLEAR_REQUESTED_ASPECTS': {
      return evolve({
        aspectsRequested: always([]),
      })(state)
    }

    // filter changed directly by some chart
    case 'STATISTICS_FILTER_CHANGED': {
      const { id, filter } = payload

      return (isEmpty(filter) ? dissocPath(['filters', `${id}`]) : assocPath(['filters', id], clone(filter)))(state)
    }

    case 'STATISTICS_CLEAR_FILTERS': {
      return evolve({
        filters: always({}),
      })(state)
    }

    // filter resetting by reset link in FilterReset
    case 'STATISTICS_FILTER_RESET': {
      const { id } = payload

      return dissocPath(['filters', `${id}`])(state)
    }

    // all filters resetting by reset filters button in command line
    case 'STATISTICS_FILTER_RESET_ALL': {
      return evolve({
        filters: always({}),
      })(state)
    }

    case 'STATISTICS_COUNT_BY_CHANGED': {
      const { by } = payload

      const newCountBy = by === 'n. of articles' ? 'count' : by

      return evolve({
        countBy: always(newCountBy),
        changedAspectsCountBy: filter((countBy) => countBy !== newCountBy),
      })(state)
    }

    /* case for special aspects (tags, sentiments, profiles and analytics) */
    case 'STATISTICS_TAG_LISTS': {
      const { aspects } = state
      let { allTags, profiles, analysis, subqueries, savedBaskets } = payload /* eslint-disable-line prefer-const */

      const tags = allTags.filter(({ type }) => type === TAG_TYPES.KEYWORD)?.map(toAspectTag)

      const sentiments = allTags.filter(({ type }) => type === TAG_TYPES.MENTOMETER)?.map(toAspectTag)

      profiles = profiles?.map(toAspectTag)
      analysis = analysis?.map(toAspectTag)

      const widgetSelection = getWidgetSelection() || '{}'
      const { baskets } = JSON.parse(widgetSelection)

      const selectedBaskets = handleSelectedBaskets(baskets, savedBaskets)

      const basketsIds = selectedBaskets?.split(',')?.map((basket) => parseInt(basket))

      // Preselect entities based on subqueries from fetch
      const checkIfSelected = (list) =>
        list?.map((item) => {
          let selected = subqueries ? subqueries?.some((sub) => sub.id === item.id) : false
          if (!!basketsIds && (item.type === 1 || item.type === 2)) {
            selected = basketsIds?.some((basket) => basket === item.id)
          }

          return {
            ...item,
            selected,
          }
        })

      const mergeListsById = (newList) => (oldList) =>
        values(
          merge(
            // @ts-expect-error: Muted so we could enable TS strict mode
            indexBy(prop('id'))(newList),
            // old should replace new, because previously selected
            // tags should remain selected
            // @ts-expect-error: Muted so we could enable TS strict mode
            indexBy(prop('id'))(oldList),
          ),
        )

      const getTagLikeEntitiesByAspectId = (aspectId) =>
        ({
          [SPECIAL_ASPECTS_IDS.TAG]: tags,
          [SPECIAL_ASPECTS_IDS.PROFILE]: profiles,
          [SPECIAL_ASPECTS_IDS.SENTIMENT]: sentiments,
          [SPECIAL_ASPECTS_IDS.ANALYSIS]: analysis,
        }[aspectId])

      const filterAspects = filter((aspect: StatisticAspect) => {
        switch (aspect.id) {
          case SPECIAL_ASPECTS_IDS.TAG:
            return tags.length > 0
          case SPECIAL_ASPECTS_IDS.SENTIMENT:
            return sentiments.length > 0
          case SPECIAL_ASPECTS_IDS.PROFILE:
            return profiles.length > 0
          case SPECIAL_ASPECTS_IDS.ANALYSIS:
            return analysis.length > 0
          default:
            return true
        }
      })

      aspects?.forEach((aspect, i) => {
        // TODO refactor
        if (values(SPECIAL_ASPECTS_IDS).includes(aspect.id)) {
          if (aspect.tagLikeEntities) {
            aspects[i] = evolve({
              tagLikeEntities: compose(mergeListsById, checkIfSelected, getTagLikeEntitiesByAspectId)(aspect.id),
            })(aspect)
          } else {
            aspect.tagLikeEntities = compose(checkIfSelected, getTagLikeEntitiesByAspectId)(aspect.id)
          }
        }
      })

      return evolve({
        // TODO only clone four special aspect not all of them
        aspects: always(clone(filterAspects(aspects))),
      })(state)
    }

    case 'STATISTICS_LOADING_IS_TAKING_TOO_LONG': {
      return assoc('loadingIsTakingTooLong', true)(state)
    }

    case 'STATISTICS_ASPECT_TAG_TOGGLED': {
      const { aspectId, tagId } = payload
      const { aspects } = state

      const aspect = aspects.find(({ id }) => id === aspectId)
      // @ts-expect-error: Muted so we could enable TS strict mode
      const tag = aspect.tagLikeEntities.find(({ id }) => id === tagId)

      // @ts-expect-error: Muted so we could enable TS strict mode
      tag.selected = !tag.selected

      const aspectIndex = indexOf(aspect)(aspects)

      return compose(
        // @ts-expect-error: Muted so we could enable TS strict mode
        assocPath(['aspects', aspectIndex, 'tagLikeEntities'], clone(aspect.tagLikeEntities)),
        assocPath(['aspects', aspectIndex, 'dirty'], true),
      )(state)
    }

    case 'STATISTIC_VIEWS_FETCH_SUCCESS':
      return assoc('list', payload, state)

    case 'STATISTICS_VIEWS_SET_ACTIVE': {
      const { id } = payload

      return {
        ...state,
        activeStatView: id,
        initialAspects: [],
        initialAspectsType: {},
        initialAspectsCountBy: {},
      }
    }

    case 'STATISTICS_VIEWS_CLEAR_ACTIVE': {
      return {
        ...state,
        activeStatView: null,
      }
    }

    case 'STATISTICS_VIEW_DELETE_SUCCESS': {
      return evolve({
        list: always(state.list.filter((item) => item.id !== payload.id)),
      })(state)
    }

    case 'STATISTICS_UPDATE_FILTERED_ARTICLES': {
      const { articles } = payload

      return evolve({
        filteredArticles: always(articles),
      })(state)
    }

    case 'STATISTICS_CLEAR_FILTERED_ARTICLES': {
      return evolve({
        filteredArticles: always([]),
      })(state)
    }

    case 'STATISTICS_UPDATE_FILTER_TYPE': {
      const { type, name } = payload
      // If the aspect type changed to default then remove from changed types
      const id = state.aspects.find((aspect) => aspect.name === name)?.id
      const shouldClearFilters = NON_FILTERABLE_GRAPH_TYPES.includes(type)

      // @ts-expect-error: Muted so we could enable TS strict mode
      if (type === defaultWidgetTypes(id)) {
        if (shouldClearFilters) {
          return compose(dissocPath(['changedAspectsType', name]), dissocPath(['filters', `${id}`]))(state)
        }

        return dissocPath(['changedAspectsType', name])(state)
      }

      if (shouldClearFilters) {
        return compose(assocPath(['changedAspectsType', name], type), dissocPath(['filters', `${id}`]))(state)
      }

      return assocPath(['changedAspectsType', name], type)(state)
    }

    case 'STATISTICS_UPDATE_FILTER_COUNTRY': {
      const { countBy, name } = payload

      // If the aspect count by changed to default count by then remove count by from changed
      if (countBy === state.countBy) {
        return dissocPath(['changedAspectsCountBy', name])(state)
      }

      return assocPath(['changedAspectsCountBy', name], countBy)(state)
    }

    case 'STATISTICS_EXPORT_TRIGGER': {
      return { ...state, exportTrigger: payload, exportLoading: true }
    }

    case 'STATISTICS_EXPORT_TRIGGER_RESET': {
      return { ...state, exportTrigger: null }
    }

    case 'STATISTICS_VIEW_EXPORT_CLOSE_MODAL':
      return {
        ...state,
        exportId: null,
        exportObject: null,
        exportLoading: false,
        statisticsExportIsTakingTooLong: false,
      }

    case 'STATISTICS_VIEW_EXPORT_PDF_FAILURE':
    case 'STATISTICS_VIEW_EXPORT_PPTX_FAILURE':
    case 'STATISTICS_VIEW_EXPORT_XLSX_FAILURE': {
      return {
        ...state,
        exportLoading: false,
        statisticsExportIsTakingTooLong: false,
        exportFormValues: null,
        exportId: null,
        exportObject: null,
      }
    }

    case 'STATISTICS_EXPORT_IS_TAKING_TOO_LONG': {
      return { ...state, statisticsExportIsTakingTooLong: true }
    }

    case 'STATISTICS_EXPORT_SUCCESS': {
      const { exportObject } = payload

      return { ...state, exportObject, exportLoading: false, statisticsExportIsTakingTooLong: false }
    }
    case 'STATISTICS_EXPORT_FAILURE': {
      return { ...state, exportLoading: false, statisticsExportIsTakingTooLong: false }
    }

    case 'STATISTICS_VIEW_RENAMING': {
      const isRenaming = payload === 'rename'

      return assoc('openRenamingPopup', isRenaming, state)
    }

    case 'GO_TO_STATISTICS_COMPARISON': {
      return { ...state, statisticsComparisonOpen: true }
    }

    case 'CLOSE_STATISTICS_COMPARISON': {
      return { ...state, statisticsComparisonOpen: false }
    }

    case 'TOGGLE_PREVIOUS_PERIOD_DATE_PICKER': {
      const {
        previousPeriod: { prevRangeEnd, prevRangeStart },
      } = state

      if (payload && !isNaN(prevRangeEnd) && !isNaN(prevRangeStart)) {
        return compose(
          assocPath(['previousPeriodDates', 'endDate'], new Date(prevRangeEnd).toISOString()),
          assocPath(['previousPeriodDates', 'startDate'], new Date(prevRangeStart).toISOString()),
          assoc('previousPeriodDatePicker', payload),
        )(state)
      } else {
        return assoc('previousPeriodDatePicker', payload, state)
      }
    }

    case 'PREVIOUS_PERIOD_DATE_PICKER_START_DATE': {
      return assocPath(['previousPeriodDates', 'startDate'], payload, state)
    }

    case 'PREVIOUS_PERIOD_DATE_PICKER_END_DATE': {
      return assocPath(['previousPeriodDates', 'endDate'], payload, state)
    }

    case 'CHANGE_COMPARE_PERIOD': {
      return assoc('comparePeriod', payload, state)
    }

    case 'STATISTICS_EXPORT_FORM_VALUES': {
      return {
        ...state,
        exportFormValues: { ...payload },
      }
    }

    case 'SEARCH_GO_TO_STATISTICS': {
      const { selectedArticles } = payload || {}

      return { ...state, checkedArticles: selectedArticles || [], selectedArticlesFetch: false }
    }

    case 'UNCHECK_ALL_ARTICLES':
      return { ...state, checkedArticles: [] }

    default:
      return state
  }

  function toAspectTag(aspect) {
    return pick(['id', 'name', 'selected', 'type'])(aspect)
  }

  function isAspectComputed(aspect) {
    return aspect.comp_part > -1
  }
  function isSepEnough(aspect) {
    return aspect.sep > 30
  }

  function wasRequested(state, aspect) {
    return state.aspectsRequested.includes(aspect.id)
  }
}

export default statisticsReducer
