import { createSelector } from 'reselect'

import { getArticleId } from '@opoint/infomedia-storybook'
import { chain, compose, contains, filter, find, intersection, map as mapR, path, pluck, propEq } from 'ramda'
import { GeneralTag, Tag } from '../components/types/tag'
import { articleIdToArticle } from '../opoint/articles'

import { M360Article } from '../opoint/articles/types'
import { getRootState, getArticles as getState } from './rootSelectors'

export const getArticles = createSelector(getState, (articlesState) => articlesState.list)

export const getEditedArticle = createSelector(getState, (articles) => articles.editedArticle)

export const getUpdatedArticle = createSelector(getState, (articles) => articles.updatedArticle)

export const getEditedArticleXHRStatus = createSelector(getState, (articles) => articles.editedArticleXHRStatus)

export const getArticlesCount = createSelector(getArticles, (articles) => articles.length)

export const getCheckedArticles = createSelector(getState, (articlesState) => articlesState.checked)

export const getCheckedArticlesCount = createSelector(getState, (articlesState) => articlesState.checked.length)

export const getShareArticleXHRStatus = createSelector(getState, (articlesState) => articlesState.shareArticleXHRStatus)

export const getShareArticleMessage = createSelector(getState, (articlesState) => articlesState.shareArticleMessage)

export const getEditedArticlesState = createSelector(getState, (articlesState) => articlesState.editedArticleState)

export const getIsCheckedAllFiltered = createSelector(getState, (articlesState) => articlesState.checkAllFiltered)

export const getShareArticleAttachmentFlag = createSelector(
  getState,
  (articlesState) => articlesState.shareArticleAttachmentFlag,
)

export const getSelectedArticlesIds = createSelector(getState, (articlesState) =>
  articlesState.checked.map((articleId) => articleIdToArticle(articleId)),
)

export const getIdentical = createSelector(getState, (articlesState) => articlesState.identical)

// Find article by ID - if you cannot find it in listing, find it in identical articles
export const getArticleById = ({ id_site, id_article }: { id_site: number; id_article: number }) => {
  const findArticleById = (articles: M360Article[]) =>
    articles.find((article) => getArticleId({ id_site, id_article }) === article.id)

  return createSelector(getArticles, (articles) => {
    const article = findArticleById(articles)

    if (article) {
      return article
    }

    const identicalDocuments = articles
      .map((article) => article.identical_documents?.document)
      .filter(Boolean)
      .flat() as M360Article[]

    return findArticleById(identicalDocuments)
  })
}

export const getSelectedArticles = createSelector(getRootState, (state) => {
  const selectedIds = getSelectedArticlesIds(state)
  return selectedIds
    .map(({ id_site, id_article }) =>
      getArticleById({ id_site: Number(id_site), id_article: Number(id_article) })(state),
    )
    .filter(Boolean) as M360Article[]
})

export const getIdenticalArticles = createSelector(getState, (articlesState) => articlesState.identical)

export const getActive = createSelector(getState, (articlesState) => articlesState.active)

export const getActiveArticleIndex = createSelector(getActive, (activeArticlesState) => activeArticlesState.index)

export const getActiveArticle = createSelector(
  getState,
  (articlesState) => articlesState.list[articlesState.active.index],
)

export const getAddArticleValues = createSelector(getState, (articlesState) => articlesState.addArticleForm)

export const getArticleTagById = ({ id: tagId }: GeneralTag, { id: articleId }: M360Article) =>
  createSelector(
    getState,
    // @ts-expect-error: Muted so we could enable TS strict mode
    compose(path(['tags', Math.abs(tagId)]), find(propEq('id', articleId)), path(['list'])),
  )

export const getArticlesTagById = ({ id: tagId }: Tag, articles: Array<M360Article>) =>
  createSelector(
    getState,
    // @ts-expect-error: Muted so we could enable TS strict mode
    compose(
      mapR(path(['tags', tagId])),

      filter(({ id }) =>
        contains(
          id,
          // @ts-expect-error: Muted so we could enable TS strict mode
          chain(({ id }) => id, articles),
        ),
      ),
      path(['list']),
    ),
  )

export const getArticleTrashTags = createSelector(
  // @ts-expect-error: Muted so we could enable TS strict mode
  (state) => state.trash.list,
  (trashTags) => (article) => {
    const articleTags = Object.keys(article?.tags ?? [])?.map((t) => parseInt(t, 10))

    // @ts-expect-error: Muted so we could enable TS strict mode
    return intersection(articleTags, pluck('id', trashTags))
  },
)

export const getListLength = createSelector(getState, path(['list', 'length']))

export const getArticleDeleteModalState = createSelector(getState, (articlesState) => articlesState.deleteModalOpen)
