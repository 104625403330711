import Cookies from 'js-cookie'
import { useCallback, useMemo } from 'react'

import useCurrentDomain from './useCurrentDomain'

export const useLeaveInsiders = (): (() => void) => {
  const currentDomain = useCurrentDomain()

  const leaveInsiders = useCallback(() => {
    const inHalfADay = 0.5
    Cookies.set('production_no_insider', true, {
      expires: inHalfADay,
      secure: true,
      sameSite: 'strict',
      domain: `.${currentDomain}`,
    })

    if (currentDomain === 'opoint.com') {
      window.location.replace(`https://m360.${currentDomain}`)

      return
    }

    window.location.replace(`https://app.${currentDomain}`)
  }, [currentDomain])

  return leaveInsiders
}

const useIsInsiders = (): boolean => {
  const isInsidersUrl = useMemo(() => {
    if (
      window?.location?.hostname?.includes('insiders') ||
      window?.location?.hostname?.includes('m360.dev.opoint.com') // TODO: Remove
    ) {
      return true
    }

    return false
  }, [])

  return isInsidersUrl
}

export default useIsInsiders
