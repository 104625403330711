import { Button, cn } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import imgUrl from '../../images/errorRobot.svg'

export type ErrorWithRetryProps = {
  primaryText: string
  secondaryText: string
  retry: () => void
  hideImage?: boolean
  className?: string
}

const ErrorWithRetry = ({ primaryText, secondaryText, retry, hideImage, className }: ErrorWithRetryProps) => (
  <div className={cn('flex w-full flex-col items-center justify-center bg-sky.9 py-6', className)}>
    <div className="flex max-w-[30rem] flex-col items-center justify-center gap-6 text-center">
      {!hideImage && (
        <div className="flex items-center justify-center rounded-full bg-grey.8">
          <img src={imgUrl} width={101} height={132} alt="Error robot image" />
        </div>
      )}

      <div>
        <p className="mb-3 text-base font-medium text-sky.1">{primaryText}</p>
        <p className="mb-0 text-sm text-sky.1">{secondaryText}</p>
      </div>

      <Button onClick={retry}>
        <Trans>Try again</Trans>
      </Button>
    </div>
  </div>
)

export default ErrorWithRetry
