import { createSelector } from 'reselect'
import { getUnixTime } from 'date-fns'

import { filter, head, isEmpty, map as mapR, values } from 'ramda'
import { filterItemsByTags, formatRangeValue } from '../helpers/listing'
import { defaultStartEndDates } from '../opoint/search'

import { SearchFilterKey } from '../components/hooks/useSearchFilters'
import { Tag } from '../components/types/tag'
import { getFolders, getProfiles, getSearch as getState, getTags } from './rootSelectors'

export const getSearchFilters = createSelector(getState, (searchState) => searchState?.searchFilters)

export const getSearchLineFiltersIds = createSelector(getState, (searchState) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  values(mapR(({ id }) => +id, searchState.searchFilters)),
)

export const getProfileTagIds = createSelector(getState, (searchState) => searchState.profileTagIds)

export const getSuggestions = createSelector(getState, (searchState) => searchState.suggestions)
export const getMainSearchLine = createSelector(getState, (searchState) => ({
  searchterm: searchState.searchterm,
  // Add all filters except timePeriod - that filter is passed as param
  filters: Object.values(filter(({ type }) => type !== SearchFilterKey.TIME_PERIOD, searchState.searchFilters)),
}))

export const getMainSearchLineWithTimePeriod = createSelector(getState, (searchState) => ({
  searchterm: searchState.searchterm,
  filters: Object.values(searchState.searchFilters),
}))

export const getSearchterm = createSelector(getState, (searchState) => searchState.searchterm)

export const isSearchNotEmpty = createSelector(
  getSearchFilters,
  getSearchterm,
  (searchFilters, searchterm) => !isEmpty(searchFilters) || !isEmpty(searchterm),
)

export const getTrashTagFilterIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.trashTagIds,
)

export const isProfileSelected = (profileId: number) =>
  createSelector(getSearchFilters, (filters) => !!filters[`profile:${profileId}`])

export const isTagSelected = (tagId: number) => createSelector(getSearchFilters, (filters) => !!filters[`tag:${tagId}`])

export const getSelectedTagIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.selectedTagIds,
)

export const getOneTagId = createSelector(getSelectedTagIds, (selectedTagIds: Array<number>): number =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  head(selectedTagIds),
)

export const getIsOneTagSelected = createSelector(getSelectedTagIds, (selectedTagIds) => selectedTagIds.length === 1)

export const getAtLeastOneTagSelected = createSelector(getSelectedTagIds, (selectedTagIds) => !!selectedTagIds.length)

export const isStatisticSelected = (statId: number) =>
  createSelector(getSearchFilters, (filters) => filters[`chart:${statId}`])

export const getSelectedProfilesIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.profileTagIds,
)

export const getSelectedProfilesNames = createSelector(
  getState,
  getProfiles,
  (searchState: any, profiles: any): Array<string> =>
    profiles.list
      .filter((profile) => searchState.profileTagIds.some((id) => id === profile.id))
      ?.map((profile) => profile.name),
)

export const getSelectedTagNames = createSelector(
  getState,
  getTags,
  (searchState: any, tags: any): Array<number> =>
    tags.list.filter((tag) => searchState.selectedTagIds.some((id) => id === tag.id))?.map((tag) => tag.name),
)

export const getSelectedProfilesAndTagsIds = createSelector(
  getSelectedProfilesIds,
  getSelectedTagIds,
  (selectedProfilesIds: Array<number>, selectedTagIds: Array<number>): Array<number> =>
    selectedProfilesIds.concat(selectedTagIds),
)

export const getProfileTagList = createSelector(getTags, getProfiles, getFolders, (tags, profiles, { folders }) => {
  const newTags = filterItemsByTags(tags.list, folders) as Tag[]

  return [...newTags, ...profiles.list]
})

export const getSearchMeta = createSelector(getState, (search) => search.meta)

// @ts-expect-error: Muted so we could enable TS strict mode
export const getSearchMetaRangeStart = createSelector(getSearchMeta, (meta) => formatRangeValue(meta?.rangeStart))

// @ts-expect-error: Muted so we could enable TS strict mode
export const getSearchMetaRangeEnd = createSelector(getSearchMeta, (meta) => formatRangeValue(meta?.rangeEnd))

export const getReceivedDocumentsCount = createSelector(getState, (search) => search.meta.receivedDocumentsCount)

// check if there are no more articles to fetch for the search
export const isSearchExhausted = createSelector(getSearchMeta, (meta) => meta.context === '')

// get start and end date from Search datepicker
export const getSearchDatepicker = createSelector(getState, (search) => search.searchDatepicker)

// get start and end date for search query from state
export const getSearchTimePeriod = createSelector(getState, (state) => {
  return Object.values(state.searchFilters).filter((filterValue) => filterValue.type === 'timePeriod')[0]
})

/*
  Order by search dates are set:
    1. search.searchDatepicker
    2. state.search.searchFilter.timePeriod.id
    3. default
*/
export const getSearchDates = createSelector(getSearchDatepicker, getSearchTimePeriod, (datePicker) => {
  if (datePicker) {
    return { startDate: new Date(datePicker.startDate), endDate: new Date(datePicker.endDate) }
  }

  return defaultStartEndDates()
})

// get start and end date range from actually loaded articles
export const getSearchActuallyLoadedDaterange = createSelector(getState, (state) => {
  return {
    startDate: state.meta.lastTimestamp || getUnixTime(new Date()),
    endDate: state.meta.firstTimestamp || getUnixTime(new Date()),
  }
})

export const isSearchInProgress = createSelector(getState, (state) => state.searchInProgress)

export const isSearchIsTakingTooLong = createSelector(getState, (state) => state.searchIsTakingTooLong)

export const getSuggestionsMultiple = createSelector(getState, (state) => state.suggestionsMultiple)

export const getFiltersShowMore = createSelector(getState, (state) => state.filtersShowMore)

export const getLoadMoreSearchInProgress = createSelector(getState, (state) => state.loadMoreSearchInProgress)

export const getAbandonedSearchLine = createSelector(getState, (state) => state.abandonedSearchLine)

export const getWikinames = createSelector(getState, (state) => state.wikinames)

export const getWikidescriptions = createSelector(getState, (state) => state.wikidescriptions)
