import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Button } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { ArrowLeft16Regular } from '@fluentui/react-icons'
import Header from '../../components/topNav/Header'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import { SearchFilterKey } from '../../components/hooks/useSearchFilters.ts'
import useSearchRouteBuilder from '../../components/hooks/useSearchRouteBuilder.ts'

const ProfilesPage = () => {
  const navigate = useNavigate()
  const { profileId } = useParams()
  const { getSearchRoute } = useSearchRouteBuilder()

  const handleExit = () => {
    navigate(getSearchRoute(SearchFilterKey.PROFILES, profileId!))
  }

  return (
    <>
      <Header>
        <div className="ml-3 flex items-center">
          <Button variant="outline" className="h-fit w-max gap-2 px-3 py-1.5" onClick={handleExit}>
            <ArrowLeft16Regular /> <Trans>Exit</Trans>
          </Button>
        </div>
        <Breadcrumbs />
      </Header>
      <Outlet />
    </>
  )
}

export default ProfilesPage
