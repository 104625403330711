import { ColumnTripleEdit24Regular } from '@fluentui/react-icons'

import {
  Button,
  Checkbox,
  cn,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { ACTIVE_COLUMNS_KEY } from '../../../constants/localStorage'
import { TableColumnDefinition } from '../../types/common.ts'

type ActiveColumnsEditorProps = {
  columns: Record<string, TableColumnDefinition>
  onActiveColumnChange: (activeColumns: Record<string, boolean>) => void
  storageKey: 'alerts' | 'agreements' | 'feeds' | 'invitations'
}

const ActiveColumnsEditor = ({
  onActiveColumnChange,
  columns,
  storageKey,
}: ActiveColumnsEditorProps): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const [activeColumns, setActiveColumns] = useLocalStorage(ACTIVE_COLUMNS_KEY, {})

  const handleCheckboxCheckedChange = (key: string, value: boolean) => {
    const columDefinition = columns[key]

    const newActiveColumns = { ...activeColumns[storageKey] }
    newActiveColumns[key] = columDefinition.forced ? true : value

    const newColumns = { ...activeColumns, [storageKey]: newActiveColumns }

    setActiveColumns(newColumns)
  }

  useEffect(() => {
    onActiveColumnChange(activeColumns[storageKey])
  }, [activeColumns, onActiveColumnChange, storageKey])

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <Tooltip>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              className="border-transparent bg-transparent hover:border-transparent hover:bg-transparent active:border-transparent"
              variant="outline"
              size="icon"
              data-cy="editActiveColumnsBtn"
            >
              <ColumnTripleEdit24Regular aria-hidden={true} />
              <span className="sr-only">
                <Trans>Edit active columns</Trans>
              </span>
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <TooltipPortal>
          <TooltipContent side="left">
            <Trans>Edit active columns</Trans>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>

      <PopoverContent className="flex w-44 flex-col space-y-4" side="left" align="start">
        {Object.entries(columns).map(([key, { label, forced }]) => {
          const id = `ActiveColumnsEditor-${key}`
          const storedValue = activeColumns[storageKey]?.[key] ?? true

          // Ensure that if a forced column is somehow hidden, the user can still revert the action.
          const disabled = forced && storedValue

          return (
            <div
              key={key}
              className={cn('flex items-center space-x-2', {
                'opacity-50': disabled,
              })}
            >
              <Checkbox
                id={id}
                disabled={disabled}
                checked={storedValue}
                onCheckedChange={(checked) => handleCheckboxCheckedChange(key, !!checked)}
              />
              <Label htmlFor={id}>{label}</Label>
            </div>
          )
        })}
      </PopoverContent>
    </Popover>
  )
}

export default ActiveColumnsEditor
