import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react'
import ErrorBoundaryContent, { ErrorBoundaryContentProps } from './ErrorBoundaryContent'

type ErrorBoundaryContextType = {
  resetAttempted: boolean
  setResetAttempted: Dispatch<SetStateAction<boolean>>
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextType>({
  resetAttempted: false,
  setResetAttempted: () => {},
})

/**
 * @deprecated Use the new IntegratedErrorBoundary component instead (src/components/common/IntegratedErrorBoundary.tsx).
 */
export const useErrorBoundaryState = () => useContext(ErrorBoundaryContext)

type ErrorBoundaryProps = PropsWithChildren<ErrorBoundaryContentProps>

/**
 * @deprecated Use the new IntegratedErrorBoundary component instead (src/components/common/IntegratedErrorBoundary.tsx).
 */
const ErrorBoundary = ({ children, ...props }: ErrorBoundaryProps) => {
  const [resetAttempted, setResetAttempted] = useState(false)

  return (
    <ErrorBoundaryContext.Provider value={{ resetAttempted, setResetAttempted }}>
      <ErrorBoundaryContent {...props}>{children}</ErrorBoundaryContent>
    </ErrorBoundaryContext.Provider>
  )
}

export default ErrorBoundary
