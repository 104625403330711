/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  DeleteProfileResponse400,
  DeletedProfilesResponse,
  IFMOrganizationProfileNumbers,
  MapIFMProfilesResponse,
  OTBList,
  OTBRule,
  PatchedProfilePatch,
  PersistentBackfill,
  PersistentError,
  Profile,
  ProfileDependenciesResponse,
  ProfileDetail,
  ProfileHistoryResponse,
  ProfilePatch,
  ProfileSort,
  ProfileUnpersistResponse,
  ProfileWatchRequest,
  ProfileWatchResponse,
  ProfilesDestroyParams,
  ProfilesHistoryRetrieveParams,
  ProfilesRetrieveParams,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * List profiles.

Also loads profiles that we have imported but not copied.
 * @summary List profiles
 */
export const useProfilesListHook = () => {
  const profilesList = useCustomAxiosInstance<Profile[]>()

  return useCallback(
    (signal?: AbortSignal) => {
      return profilesList({ url: `/profiles/`, method: 'GET', signal })
    },
    [profilesList],
  )
}

export const getProfilesListQueryKey = () => {
  return [`/profiles/`] as const
}

export const useProfilesListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesListQueryKey()

  const profilesList = useProfilesListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>> = ({ signal }) =>
    profilesList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>>
export type ProfilesListQueryError = ErrorType<unknown>

/**
 * @summary List profiles
 */
export const useProfilesList = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Creates a profile
 * @summary Create a profile
 */
export const useProfilesCreateHook = () => {
  const profilesCreate = useCustomAxiosInstance<ProfileDetail>()

  return useCallback(
    (profileDetail: NonReadonly<ProfileDetail>) => {
      return profilesCreate({
        url: `/profiles/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: profileDetail,
      })
    },
    [profilesCreate],
  )
}

export const useProfilesCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>,
    TError,
    { data: NonReadonly<ProfileDetail> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>,
  TError,
  { data: NonReadonly<ProfileDetail> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesCreate = useProfilesCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>,
    { data: NonReadonly<ProfileDetail> }
  > = (props) => {
    const { data } = props ?? {}

    return profilesCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>>
export type ProfilesCreateMutationBody = NonReadonly<ProfileDetail>
export type ProfilesCreateMutationError = ErrorType<void>

/**
 * @summary Create a profile
 */
export const useProfilesCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>,
    TError,
    { data: NonReadonly<ProfileDetail> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesCreateHook>>>,
  TError,
  { data: NonReadonly<ProfileDetail> },
  TContext
> => {
  const mutationOptions = useProfilesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Deletes history item for a provided profile
 * @summary Delete history item
 */
export const useProfilesDestroy2Hook = () => {
  const profilesDestroy2 = useCustomAxiosInstance<void>()

  return useCallback(
    (idList: number, timestamp: number) => {
      return profilesDestroy2({ url: `/profiles/${idList}/${timestamp}/`, method: 'DELETE' })
    },
    [profilesDestroy2],
  )
}

export const useProfilesDestroy2MutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>,
    TError,
    { idList: number; timestamp: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>,
  TError,
  { idList: number; timestamp: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesDestroy2 = useProfilesDestroy2Hook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>,
    { idList: number; timestamp: number }
  > = (props) => {
    const { idList, timestamp } = props ?? {}

    return profilesDestroy2(idList, timestamp)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesDestroy2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>
>

export type ProfilesDestroy2MutationError = ErrorType<void>

/**
 * @summary Delete history item
 */
export const useProfilesDestroy2 = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>,
    TError,
    { idList: number; timestamp: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesDestroy2Hook>>>,
  TError,
  { idList: number; timestamp: number },
  TContext
> => {
  const mutationOptions = useProfilesDestroy2MutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Retrieves a profile based on profile id
 * @summary Retrieve a profile
 */
export const useProfilesRetrieveHook = () => {
  const profilesRetrieve = useCustomAxiosInstance<ProfileDetail>()

  return useCallback(
    (idList: number, params?: ProfilesRetrieveParams, signal?: AbortSignal) => {
      return profilesRetrieve({ url: `/profiles/${idList}/`, method: 'GET', params, signal })
    },
    [profilesRetrieve],
  )
}

export const getProfilesRetrieveQueryKey = (idList: number, params?: ProfilesRetrieveParams) => {
  return [`/profiles/${idList}/`, ...(params ? [params] : [])] as const
}

export const useProfilesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  params?: ProfilesRetrieveParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesRetrieveQueryKey(idList, params)

  const profilesRetrieve = useProfilesRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>> = ({ signal }) =>
    profilesRetrieve(idList, params, signal)

  return { queryKey, queryFn, enabled: !!idList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesRetrieveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>>
export type ProfilesRetrieveQueryError = ErrorType<void>

/**
 * @summary Retrieve a profile
 */
export const useProfilesRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  params?: ProfilesRetrieveParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesRetrieveHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesRetrieveQueryOptions(idList, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Legacy system (m3) relies on the constraint defined in
MifluzListCont table - UNIQUE KEY `id_list` (`id_list`,`line`)

Because of this, we need to emulate what legacy system was doing, that
is deleting all relevant MifluzListCont entries and create them again.

We might change this behavior once previous versions don't rely on them.
 * @summary Update a profile
 */
export const useProfilesUpdateHook = () => {
  const profilesUpdate = useCustomAxiosInstance<ProfileDetail>()

  return useCallback(
    (idList: number, profileDetail: NonReadonly<ProfileDetail>) => {
      return profilesUpdate({
        url: `/profiles/${idList}/`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: profileDetail,
      })
    },
    [profilesUpdate],
  )
}

export const useProfilesUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<ProfileDetail> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<ProfileDetail> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesUpdate = useProfilesUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>,
    { idList: number; data: NonReadonly<ProfileDetail> }
  > = (props) => {
    const { idList, data } = props ?? {}

    return profilesUpdate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesUpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>>
export type ProfilesUpdateMutationBody = NonReadonly<ProfileDetail>
export type ProfilesUpdateMutationError = ErrorType<void>

/**
 * @summary Update a profile
 */
export const useProfilesUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<ProfileDetail> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<ProfileDetail> },
  TContext
> => {
  const mutationOptions = useProfilesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Update profile configuration
 */
export const useProfilesPartialUpdateHook = () => {
  const profilesPartialUpdate = useCustomAxiosInstance<ProfilePatch>()

  return useCallback(
    (idList: number, patchedProfilePatch: PatchedProfilePatch) => {
      return profilesPartialUpdate({
        url: `/profiles/${idList}/`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: patchedProfilePatch,
      })
    },
    [profilesPartialUpdate],
  )
}

export const useProfilesPartialUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>,
    TError,
    { idList: number; data: PatchedProfilePatch },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>,
  TError,
  { idList: number; data: PatchedProfilePatch },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesPartialUpdate = useProfilesPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>,
    { idList: number; data: PatchedProfilePatch }
  > = (props) => {
    const { idList, data } = props ?? {}

    return profilesPartialUpdate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>
>
export type ProfilesPartialUpdateMutationBody = PatchedProfilePatch
export type ProfilesPartialUpdateMutationError = ErrorType<void>

/**
 * @summary Update profile configuration
 */
export const useProfilesPartialUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>,
    TError,
    { idList: number; data: PatchedProfilePatch },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesPartialUpdateHook>>>,
  TError,
  { idList: number; data: PatchedProfilePatch },
  TContext
> => {
  const mutationOptions = useProfilesPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Destroy a model instance.
 * @summary Remove a profile
 */
export const useProfilesDestroyHook = () => {
  const profilesDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (idList: number, params?: ProfilesDestroyParams) => {
      return profilesDestroy({ url: `/profiles/${idList}/`, method: 'DELETE', params })
    },
    [profilesDestroy],
  )
}

export const useProfilesDestroyMutationOptions = <
  TError = ErrorType<DeleteProfileResponse400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>,
    TError,
    { idList: number; params?: ProfilesDestroyParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>,
  TError,
  { idList: number; params?: ProfilesDestroyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesDestroy = useProfilesDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>,
    { idList: number; params?: ProfilesDestroyParams }
  > = (props) => {
    const { idList, params } = props ?? {}

    return profilesDestroy(idList, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesDestroyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>>

export type ProfilesDestroyMutationError = ErrorType<DeleteProfileResponse400>

/**
 * @summary Remove a profile
 */
export const useProfilesDestroy = <TError = ErrorType<DeleteProfileResponse400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>,
    TError,
    { idList: number; params?: ProfilesDestroyParams },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesDestroyHook>>>,
  TError,
  { idList: number; params?: ProfilesDestroyParams },
  TContext
> => {
  const mutationOptions = useProfilesDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Backfill a persistent profile
 */
export const useProfilesBackfillCreateHook = () => {
  const profilesBackfillCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (idList: number, persistentBackfill: PersistentBackfill) => {
      return profilesBackfillCreate({
        url: `/profiles/${idList}/backfill/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: persistentBackfill,
      })
    },
    [profilesBackfillCreate],
  )
}

export const useProfilesBackfillCreateMutationOptions = <
  TError = ErrorType<PersistentError | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>,
    TError,
    { idList: number; data: PersistentBackfill },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>,
  TError,
  { idList: number; data: PersistentBackfill },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesBackfillCreate = useProfilesBackfillCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>,
    { idList: number; data: PersistentBackfill }
  > = (props) => {
    const { idList, data } = props ?? {}

    return profilesBackfillCreate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesBackfillCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>
>
export type ProfilesBackfillCreateMutationBody = PersistentBackfill
export type ProfilesBackfillCreateMutationError = ErrorType<PersistentError | void>

/**
 * @summary Backfill a persistent profile
 */
export const useProfilesBackfillCreate = <TError = ErrorType<PersistentError | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>,
    TError,
    { idList: number; data: PersistentBackfill },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesBackfillCreateHook>>>,
  TError,
  { idList: number; data: PersistentBackfill },
  TContext
> => {
  const mutationOptions = useProfilesBackfillCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns all dependencies for a profile.
 * @summary Get profile's dependencies
 */
export const useProfilesDependenciesRetrieveHook = () => {
  const profilesDependenciesRetrieve = useCustomAxiosInstance<ProfileDependenciesResponse>()

  return useCallback(
    (idList: number, signal?: AbortSignal) => {
      return profilesDependenciesRetrieve({ url: `/profiles/${idList}/dependencies/`, method: 'GET', signal })
    },
    [profilesDependenciesRetrieve],
  )
}

export const getProfilesDependenciesRetrieveQueryKey = (idList: number) => {
  return [`/profiles/${idList}/dependencies/`] as const
}

export const useProfilesDependenciesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idList: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesDependenciesRetrieveQueryKey(idList)

  const profilesDependenciesRetrieve = useProfilesDependenciesRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>> = ({
    signal,
  }) => profilesDependenciesRetrieve(idList, signal)

  return { queryKey, queryFn, enabled: !!idList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesDependenciesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>
>
export type ProfilesDependenciesRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get profile's dependencies
 */
export const useProfilesDependenciesRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idList: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesDependenciesRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesDependenciesRetrieveQueryOptions(idList, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Return list of all stored history items for a provided profile
 * @summary Get profile's history
 */
export const useProfilesHistoryRetrieveHook = () => {
  const profilesHistoryRetrieve = useCustomAxiosInstance<ProfileHistoryResponse>()

  return useCallback(
    (idList: number, params?: ProfilesHistoryRetrieveParams, signal?: AbortSignal) => {
      return profilesHistoryRetrieve({ url: `/profiles/${idList}/history/`, method: 'GET', params, signal })
    },
    [profilesHistoryRetrieve],
  )
}

export const getProfilesHistoryRetrieveQueryKey = (idList: number, params?: ProfilesHistoryRetrieveParams) => {
  return [`/profiles/${idList}/history/`, ...(params ? [params] : [])] as const
}

export const useProfilesHistoryRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idList: number,
  params?: ProfilesHistoryRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesHistoryRetrieveQueryKey(idList, params)

  const profilesHistoryRetrieve = useProfilesHistoryRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>> = ({ signal }) =>
    profilesHistoryRetrieve(idList, params, signal)

  return { queryKey, queryFn, enabled: !!idList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesHistoryRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>
>
export type ProfilesHistoryRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get profile's history
 */
export const useProfilesHistoryRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idList: number,
  params?: ProfilesHistoryRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesHistoryRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesHistoryRetrieveQueryOptions(idList, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Unpersist a profile
 */
export const useProfilesUnpersistCreateHook = () => {
  const profilesUnpersistCreate = useCustomAxiosInstance<ProfileUnpersistResponse>()

  return useCallback(
    (idList: number, profile: NonReadonly<Profile>) => {
      return profilesUnpersistCreate({
        url: `/profiles/${idList}/unpersist/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: profile,
      })
    },
    [profilesUnpersistCreate],
  )
}

export const useProfilesUnpersistCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>,
    TError,
    { idList: number; data: NonReadonly<Profile> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>,
  TError,
  { idList: number; data: NonReadonly<Profile> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesUnpersistCreate = useProfilesUnpersistCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>,
    { idList: number; data: NonReadonly<Profile> }
  > = (props) => {
    const { idList, data } = props ?? {}

    return profilesUnpersistCreate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesUnpersistCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>
>
export type ProfilesUnpersistCreateMutationBody = NonReadonly<Profile>
export type ProfilesUnpersistCreateMutationError = ErrorType<void>

/**
 * @summary Unpersist a profile
 */
export const useProfilesUnpersistCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>,
    TError,
    { idList: number; data: NonReadonly<Profile> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesUnpersistCreateHook>>>,
  TError,
  { idList: number; data: NonReadonly<Profile> },
  TContext
> => {
  const mutationOptions = useProfilesUnpersistCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Clears watch cache. You can specify `profile` (str, defaults to empty string) name and `mode` (int, defaults to 2) in the request body.
 * @summary Clear watch cache
 */
export const useProfilesClearWatchCacheDestroyHook = () => {
  const profilesClearWatchCacheDestroy = useCustomAxiosInstance<void>()

  return useCallback(() => {
    return profilesClearWatchCacheDestroy({ url: `/profiles/clear-watch-cache/`, method: 'DELETE' })
  }, [profilesClearWatchCacheDestroy])
}

export const useProfilesClearWatchCacheDestroyMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesClearWatchCacheDestroy = useProfilesClearWatchCacheDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>,
    void
  > = () => {
    return profilesClearWatchCacheDestroy()
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesClearWatchCacheDestroyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>
>

export type ProfilesClearWatchCacheDestroyMutationError = ErrorType<void>

/**
 * @summary Clear watch cache
 */
export const useProfilesClearWatchCacheDestroy = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesClearWatchCacheDestroyHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useProfilesClearWatchCacheDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns all deleted profiles for a user.
 * @summary Get deleted profiles
 */
export const useProfilesDeletedRetrieveHook = () => {
  const profilesDeletedRetrieve = useCustomAxiosInstance<DeletedProfilesResponse>()

  return useCallback(
    (signal?: AbortSignal) => {
      return profilesDeletedRetrieve({ url: `/profiles/deleted/`, method: 'GET', signal })
    },
    [profilesDeletedRetrieve],
  )
}

export const getProfilesDeletedRetrieveQueryKey = () => {
  return [`/profiles/deleted/`] as const
}

export const useProfilesDeletedRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesDeletedRetrieveQueryKey()

  const profilesDeletedRetrieve = useProfilesDeletedRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>> = ({ signal }) =>
    profilesDeletedRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesDeletedRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>
>
export type ProfilesDeletedRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get deleted profiles
 */
export const useProfilesDeletedRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesDeletedRetrieveHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesDeletedRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns list id for the requested IFM Profile names
 * @summary Map IFM profiles
 */
export const useProfilesMapIfmProfilesCreateHook = () => {
  const profilesMapIfmProfilesCreate = useCustomAxiosInstance<MapIFMProfilesResponse>()

  return useCallback(
    (iFMOrganizationProfileNumbers: IFMOrganizationProfileNumbers) => {
      return profilesMapIfmProfilesCreate({
        url: `/profiles/map_ifm_profiles/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: iFMOrganizationProfileNumbers,
      })
    },
    [profilesMapIfmProfilesCreate],
  )
}

export const useProfilesMapIfmProfilesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>,
    TError,
    { data: IFMOrganizationProfileNumbers },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>,
  TError,
  { data: IFMOrganizationProfileNumbers },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesMapIfmProfilesCreate = useProfilesMapIfmProfilesCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>,
    { data: IFMOrganizationProfileNumbers }
  > = (props) => {
    const { data } = props ?? {}

    return profilesMapIfmProfilesCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesMapIfmProfilesCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>
>
export type ProfilesMapIfmProfilesCreateMutationBody = IFMOrganizationProfileNumbers
export type ProfilesMapIfmProfilesCreateMutationError = ErrorType<unknown>

/**
 * @summary Map IFM profiles
 */
export const useProfilesMapIfmProfilesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>,
    TError,
    { data: IFMOrganizationProfileNumbers },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesMapIfmProfilesCreateHook>>>,
  TError,
  { data: IFMOrganizationProfileNumbers },
  TContext
> => {
  const mutationOptions = useProfilesMapIfmProfilesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Runs the OTB parser for the given list id and returns data
 * @summary Opoint topic builder (OTB) list detail
 */
export const useProfilesOtbListDetailRetrieveHook = () => {
  const profilesOtbListDetailRetrieve = useCustomAxiosInstance<OTBList>()

  return useCallback(
    (otbIdList: string, signal?: AbortSignal) => {
      return profilesOtbListDetailRetrieve({ url: `/profiles/otb-list-detail/${otbIdList}/`, method: 'GET', signal })
    },
    [profilesOtbListDetailRetrieve],
  )
}

export const getProfilesOtbListDetailRetrieveQueryKey = (otbIdList: string) => {
  return [`/profiles/otb-list-detail/${otbIdList}/`] as const
}

export const useProfilesOtbListDetailRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  otbIdList: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesOtbListDetailRetrieveQueryKey(otbIdList)

  const profilesOtbListDetailRetrieve = useProfilesOtbListDetailRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>> = ({
    signal,
  }) => profilesOtbListDetailRetrieve(otbIdList, signal)

  return { queryKey, queryFn, enabled: !!otbIdList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesOtbListDetailRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>
>
export type ProfilesOtbListDetailRetrieveQueryError = ErrorType<void>

/**
 * @summary Opoint topic builder (OTB) list detail
 */
export const useProfilesOtbListDetailRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  otbIdList: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesOtbListDetailRetrieveQueryOptions(otbIdList, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Runs the OTB parser for the given list id and returns data
 * @summary Opoint topic builder (OTB) list detail
 */
export const useProfilesOtbListDetailRetrieve2Hook = () => {
  const profilesOtbListDetailRetrieve2 = useCustomAxiosInstance<OTBList>()

  return useCallback(
    (otbIdRule: number, signal?: AbortSignal) => {
      return profilesOtbListDetailRetrieve2({ url: `/profiles/otb-list-detail/${otbIdRule}/`, method: 'GET', signal })
    },
    [profilesOtbListDetailRetrieve2],
  )
}

export const getProfilesOtbListDetailRetrieve2QueryKey = (otbIdRule: number) => {
  return [`/profiles/otb-list-detail/${otbIdRule}/`] as const
}

export const useProfilesOtbListDetailRetrieve2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>,
  TError = ErrorType<void>,
>(
  otbIdRule: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>,
      TError,
      TData
    >
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesOtbListDetailRetrieve2QueryKey(otbIdRule)

  const profilesOtbListDetailRetrieve2 = useProfilesOtbListDetailRetrieve2Hook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>> = ({
    signal,
  }) => profilesOtbListDetailRetrieve2(otbIdRule, signal)

  return { queryKey, queryFn, enabled: !!otbIdRule, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesOtbListDetailRetrieve2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>
>
export type ProfilesOtbListDetailRetrieve2QueryError = ErrorType<void>

/**
 * @summary Opoint topic builder (OTB) list detail
 */
export const useProfilesOtbListDetailRetrieve2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>,
  TError = ErrorType<void>,
>(
  otbIdRule: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useProfilesOtbListDetailRetrieve2Hook>>>,
      TError,
      TData
    >
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesOtbListDetailRetrieve2QueryOptions(otbIdRule, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Runs the OTB parser for the given rule id and returns data from the parser
 * @summary Opoint topic builder (OTB) rule detail
 */
export const useProfilesOtbRuleDetailRetrieveHook = () => {
  const profilesOtbRuleDetailRetrieve = useCustomAxiosInstance<OTBRule>()

  return useCallback(
    (otbIdRule: string, signal?: AbortSignal) => {
      return profilesOtbRuleDetailRetrieve({ url: `/profiles/otb-rule-detail/${otbIdRule}/`, method: 'GET', signal })
    },
    [profilesOtbRuleDetailRetrieve],
  )
}

export const getProfilesOtbRuleDetailRetrieveQueryKey = (otbIdRule: string) => {
  return [`/profiles/otb-rule-detail/${otbIdRule}/`] as const
}

export const useProfilesOtbRuleDetailRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  otbIdRule: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProfilesOtbRuleDetailRetrieveQueryKey(otbIdRule)

  const profilesOtbRuleDetailRetrieve = useProfilesOtbRuleDetailRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>> = ({
    signal,
  }) => profilesOtbRuleDetailRetrieve(otbIdRule, signal)

  return { queryKey, queryFn, enabled: !!otbIdRule, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProfilesOtbRuleDetailRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>
>
export type ProfilesOtbRuleDetailRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Opoint topic builder (OTB) rule detail
 */
export const useProfilesOtbRuleDetailRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  otbIdRule: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfilesOtbRuleDetailRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfilesOtbRuleDetailRetrieveQueryOptions(otbIdRule, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Endpoint for sorting profiles
 * @summary Sort profiles
 */
export const useProfilesSortCreateHook = () => {
  const profilesSortCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (profileSort: ProfileSort) => {
      return profilesSortCreate({
        url: `/profiles/sort/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: profileSort,
      })
    },
    [profilesSortCreate],
  )
}

export const useProfilesSortCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>,
    TError,
    { data: ProfileSort },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>,
  TError,
  { data: ProfileSort },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesSortCreate = useProfilesSortCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>,
    { data: ProfileSort }
  > = (props) => {
    const { data } = props ?? {}

    return profilesSortCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesSortCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>
>
export type ProfilesSortCreateMutationBody = ProfileSort
export type ProfilesSortCreateMutationError = ErrorType<void>

/**
 * @summary Sort profiles
 */
export const useProfilesSortCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>,
    TError,
    { data: ProfileSort },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesSortCreateHook>>>,
  TError,
  { data: ProfileSort },
  TContext
> => {
  const mutationOptions = useProfilesSortCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns watched profiles
 * @summary Watch profile
 */
export const useProfilesWatchCreateHook = () => {
  const profilesWatchCreate = useCustomAxiosInstance<ProfileWatchResponse>()

  return useCallback(
    (profileWatchRequest: ProfileWatchRequest) => {
      return profilesWatchCreate({
        url: `/profiles/watch/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: profileWatchRequest,
      })
    },
    [profilesWatchCreate],
  )
}

export const useProfilesWatchCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>,
    TError,
    { data: ProfileWatchRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>,
  TError,
  { data: ProfileWatchRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const profilesWatchCreate = useProfilesWatchCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>,
    { data: ProfileWatchRequest }
  > = (props) => {
    const { data } = props ?? {}

    return profilesWatchCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProfilesWatchCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>
>
export type ProfilesWatchCreateMutationBody = ProfileWatchRequest
export type ProfilesWatchCreateMutationError = ErrorType<unknown>

/**
 * @summary Watch profile
 */
export const useProfilesWatchCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>,
    TError,
    { data: ProfileWatchRequest },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useProfilesWatchCreateHook>>>,
  TError,
  { data: ProfileWatchRequest },
  TContext
> => {
  const mutationOptions = useProfilesWatchCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
