import { createSelector, Selector } from 'reselect'

import { flatten, isEmpty, uniq } from 'ramda'
import { ExportFileFormValues } from '../components/statistics/export/Export'
import { getWidgetSelection, setWidgetSelection } from '../components/statistics/statisticsControllers'
import { ExportObject } from '../components/types/statistics'
import { getSelectedTagLikeEntities, SPECIAL_ASPECTS_IDS } from '../opoint/statistics/aspects'

import { getStatistics as getState } from './rootSelectors'

export const getAspects = createSelector(getState, (statState) => statState.aspects)

export const getInitialAspects = createSelector(getState, (statState) => statState.initialAspects)
export const getInitialAspectsType = createSelector(getState, (statState) => statState.initialAspectsType)
export const getInitialAspectsCountBy = createSelector(getState, (statState) => statState.initialAspectsCountBy)

export const getActiveViewId = createSelector(getState, (statState) => statState.activeStatView)

export const getActiveStatView = createSelector(getState, getActiveViewId, (statState, id) =>
  statState.list.find((view) => view.id === id),
)

export const getSelectedAspects = createSelector(
  getState,
  getAspects,
  getActiveStatView,
  (statState, aspects, activeStatView) => {
    const selectedAspects = aspects.filter(({ selected }) => selected)
    const isStatView = !!activeStatView

    if (!isStatView && statState.regularStatsFetch && selectedAspects.length > 0) {
      const selectedAspectIds = selectedAspects?.map((aspect) => aspect.id)
      const aspectGroups = selectedAspects?.map((aspect) => aspect.group)

      const computeGroups = uniq(aspectGroups)

      const widgetSelection = getWidgetSelection() || '{}'
      const parsedWidgetSelection = JSON.parse(widgetSelection)
      setWidgetSelection(
        JSON.stringify({
          ...parsedWidgetSelection,
          selectedAspectIds,
          computeGroups: computeGroups.reduce((a: number, b: number) => a + b, 0),
        }),
      )
    }

    return selectedAspects
  },
)

export const getAspectById = (aspectId: number) =>
  createSelector(getAspects, (aspects) => aspects.find(({ id }) => aspectId === id))

export const areStatisticsFiltered = createSelector(getState, (statState) => !isEmpty(statState.filters))

/*
 * selector for selecting selected tags of special aspects
 */

const getSelectedTagLikeEntitiesOfSelectedAspect = (aspect) =>
  (aspect && aspect.selected && getSelectedTagLikeEntities(aspect)) || []

export const getSelectedTags = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.TAG),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedSentiments = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.SENTIMENT),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedAnalysis = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.ANALYSIS),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedProfiles = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.PROFILE),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSubQueries = createSelector(getSelectedProfiles, (profiles) => profiles?.map(({ id }) => ({ id })))

export const getBaskets = createSelector(
  getSelectedTags,
  getSelectedSentiments,
  getSelectedAnalysis,
  (tags, sentiments, analysis) => [...tags, ...sentiments, ...analysis]?.map(({ id }) => id).join(','),
)

export const getFilteredArticles = createSelector(getState, (statState) => statState.filteredArticles)

export const getShowFilteredArticles = createSelector(getState, (statState) => statState.showFilteredArticles)

export const getChangedAspectsType = createSelector(getState, (statState) => statState.changedAspectsType)

export const getChangedAspectsCountBy = createSelector(getState, (statState) => statState.changedAspectsCountBy)

export const getStatisticsList = createSelector(getState, (statState) => statState.list)

export const getFirstStatistics = createSelector(getStatisticsList, (statistics) => statistics?.[0])

export const getAspectCombo = createSelector(getState, (statState) =>
  flatten(
    statState.aspects
      .filter(({ overlap }) => overlap > 0)
      ?.map(({ id, overlap, overlapMode }) => [id, overlapMode * overlap]),
  ),
)

export const getExportTrigger = createSelector(getState, (statState) => statState.exportTrigger)

export const getCountBy = createSelector(getState, (statState) => statState.countBy)

export const getComputedAspectGroup = createSelector(getState, (statState) => statState.computedAspectGroup)

export const getStatDocuments = createSelector(getState, (statState) => statState.documents)

export const getStatFilters = createSelector(getState, (statState) => statState.filters)

export const isStatLoading = createSelector(getState, (statState) => statState.loading)

export const getAspectsRequested = createSelector(getState, (statState) => statState.aspectsRequested)

export const getCorrectStatisticsDates = createSelector(getState, (statState) => statState.correctStatisticsDates)

export const getRenamingOpenPopupState = createSelector(getState, (statState) => statState.openRenamingPopup)

export const getComparisonModeState = createSelector(getState, (statState) => statState.statisticsComparisonOpen)

export const getPreviousPeriodDatePickerState = createSelector(
  getState,
  (statState) => statState.previousPeriodDatePicker,
)

export const getPreviousPeriodDates = createSelector(getState, (statState) => statState.previousPeriodDates)

export const getComparePeriod = createSelector(getState, (statState) => statState.comparePeriod)

export const getComparedDocuments = createSelector(getState, (statState) => statState.comparedDocuments)

export const getRangePreviousPeriod = createSelector(getState, (statState) => statState.previousPeriod)

export const getCompareStatsLoading = createSelector(getState, (statState) => statState.compareStatsLoading)

export const getStatisticsViewArticles = createSelector(getState, (statState) => statState.articles)

export const getLoadingIsTakingTooLong = createSelector(getState, (statState) => statState.loadingIsTakingTooLong)

export const getExportId: Selector<any, number> = createSelector(getState, (statState) => statState.exportId)

// @ts-expect-error: Muted so we could enable TS strict mode
export const getStatisticsExportObject: Selector<any, ExportObject> = createSelector(
  getState,
  (statState) => statState.exportObject,
)

export const getStatisticsExportIsTakingTooLong: Selector<any, boolean> = createSelector(
  getState,
  (statState) => statState.statisticsExportIsTakingTooLong,
)

export const getStatisticsExportLoading = createSelector(getState, (statState) => statState.exportLoading)

export const getExportFormValues = createSelector(
  getState,
  (statState) => statState.exportFormValues as ExportFileFormValues,
)

export const getStatisticsCheckedArticles = createSelector(getState, (statState) => statState.checkedArticles)

export const getIsSelectedArticlesFetch = createSelector(getState, (statState) => statState.selectedArticlesFetch)
