import copy from 'copy-to-clipboard'
import { fromUnixTime, isAfter } from 'date-fns'

import { t } from 'i18next'
import {
  allPass,
  always,
  compose,
  concat,
  cond,
  curry,
  eqBy,
  find,
  is,
  map,
  path,
  prop as propR,
  propEq,
  sortBy,
  T,
  toLower,
  uniq,
  when,
  assoc,
  differenceWith,
  eqProps,
} from 'ramda'
import { ContactTypes } from '../../components/constants/suggestions'
import { ContactFormValues } from '../../components/contacts/ContactsForm/ContactEditor/types'
import { GroupFormValues } from '../../components/contacts/ContactsForm/GroupEditor/types'
import { CommonRecipientFilter, ContactDetail, ContactFilter } from '../../components/types/contact'
import { isGroup } from '../../guards/isContact'
import config from '../common/config'
import type { Contact, Group, Recipient } from '../flow'
import { handleErrors } from '../common'

// @sig Contact -> Contact -> boolean
// @ts-expect-error: Muted so we could enable TS strict mode
export const eqContacts = eqBy(propR('id'))

// @sig ContactFilter -> ContactFilter -> boolean
// @ts-expect-error: Muted so we could enable TS strict mode
export const eqContactFilters = allPass([eqBy(propR('type')), eqBy(path(['entity', 'id']))])

// @sig ContactFilter -> string
export const getContactFilterName = cond([
  // @ts-expect-error: Muted so we could enable TS strict mode
  [propEq('type', 'person'), ({ entity: contact }) => getContactFullName(contact)],
  // @ts-expect-error: Muted so we could enable TS strict mode
  [propEq('type', 'user'), ({ entity: contact }) => getContactFullName(contact)],
  [propEq('type', 'group'), path(['entity', 'name'])],
  [propEq('type', 'email'), path(['entity', 'value'])],
  [propEq('type', 'phoneNumber'), path(['entity', 'value'])],
  [T, always('Unknown type')],
])

export const getContactTypeAndName = (suggestion: ContactFilter | CommonRecipientFilter) => {
  const { type } = suggestion

  switch (type) {
    case ContactTypes.Person:
      return { name: getContactFullName(suggestion.entity as Contact), type: t('Contact') }

    case ContactTypes.User:
      return { name: getContactFullName(suggestion.entity as Contact), type: t('User') }

    case ContactTypes.Group:
      return { name: isGroup(suggestion.entity) && suggestion.entity.name, type: t('Group') }

    case ContactTypes.Email:
      return { name: suggestion.entity.value, type: t('Email') }

    case ContactTypes.PhoneNumber:
      return { name: suggestion.entity.value, type: t('Phone number') }

    default:
      return { name: t('Unknown type'), type: t('Unknown type') }
  }
}

export const contactRecipientDataTransformToFilter = (
  recipients: Recipient,
  listByType: { person: Array<ContactDetail>; group: Array<Group> },
) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  map(
    (recipient) => ({
      // @ts-expect-error: Muted so we could enable TS strict mode
      type: recipient.type,
      // @ts-expect-error: Muted so we could enable TS strict mode
      entity: listByType[recipient.type]
        ? // @ts-expect-error: Muted so we could enable TS strict mode
          find(propEq('id', recipient.id))(listByType[recipient.type])
        : {
            // @ts-expect-error: Muted so we could enable TS strict mode
            ...recipient,
            // @ts-expect-error: Muted so we could enable TS strict mode
            id: recipient.value ?? recipient.id, // Fallback to when type === user
          },
    }),
    recipients,
  )

/**
 * Retrieves contacts from API and transforms them into a Promise.
 * @returns {*}
 */
export const getContacts = async (): Promise<ContactDetail[]> => {
  const url = config.url.api('/contacts/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getContactFullName = (contact: Contact) => {
  // @ts-expect-error: Muted so we could enable TS strict mode
  const firstName = contact.firstName ?? contact.name
  const lastName = contact.lastName ?? ''

  return `${firstName} ${lastName}`
}

export const getContactDetail = async (contactId: number): Promise<ContactDetail> => {
  const url = config.url.api(`/contacts/${contactId}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
export const saveContact = async (
  contactId: number | string,
  updatedData: ContactFormValues,
): Promise<ContactDetail> => {
  const url = contactId ? config.url.api(`/contacts/${contactId}/`) : config.url.api('/contacts/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: contactId ? 'PUT' : 'POST',
    body: JSON.stringify(updatedData),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteContact = async (contactId: number | string, locale = 'en-GB', forceFlag = false) => {
  const url = config.url.api(`/contacts/${contactId}${forceFlag ? '/?force=true' : ''}/`)

  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, {
    ...requestHeaders,
    method: 'DELETE',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.text())
}

export const getGroups = async (): Promise<Group[]> => {
  const url = config.url.api('/contact-groups/')
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getGroupDetail = async (groupId: number): Promise<Group> => {
  const url = config.url.api(`/contact-groups/${groupId}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const saveGroup = async (data: GroupFormValues): Promise<Group> => {
  const url = data.id ? config.url.api(`/contact-groups/${data.id}/`) : config.url.api('/contact-groups/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: data.id ? 'PUT' : 'POST',
    body: JSON.stringify(data),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteGroup = async (groupId: number, locale = 'en-GB'): Promise<string> => {
  const url = config.url.api(`/contact-groups/${groupId}/`)

  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, {
    ...requestHeaders,
    method: 'DELETE',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.text())
}

export const checkedGroupProp = 'checked' // name of group prop for redux form checkbox(True/False)

export const checkGroup = (group: Group) => {
  return assoc(checkedGroupProp, true, group)
}

export const sortByProp: (propToBeSortBy: string, groupList: Array<Group>) => Array<Group> = curry((prop, list) =>
  sortBy(
    compose(
      when(
        is(String),
        toLower, // upper case props won't be before lowercase props
      ),
      // @ts-expect-error: Muted so we could enable TS strict mode
      propR(prop),
    ),
    list,
  ),
)

/*
  Add all existing groups into empty contact
*/
export const createEmptyContactWithGroups = (groups: Array<Group>) => {
  return {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    email_forwards: false,
    pause: null,
    groups,
  }
}

/*
  Concat all existing groups with groups that belogs to specific contact, without duplicates
*/
export const joinCheckGroupsWithRest = (groupList: Array<Group>, contactGroups: Array<Group> = []) => {
  const checkedGroups = map(checkGroup, contactGroups) // set check for contact specific groups
  const uncheckedGroups = differenceWith((x, y) => eqProps('id', x, y), groupList, checkedGroups)

  return concat(checkedGroups, uncheckedGroups)
}

/*
  Check if timestamp value is validly defined and in the future, because if
  pause timestamp is in the past it's not active
*/
export const isAlertPauseTimeStampValid = (timestamp: number): boolean => {
  if (timestamp) {
    return isAfter(fromUnixTime(timestamp), new Date())
  }

  return false
}

// Copies recipients info to clipboard
// infoType parameter determines which info to copy (email or mobile)
export const copyRecipientInfo = async (
  recipients: Array<ContactFilter | CommonRecipientFilter>,
  infoType: 'email' | 'mobile',
  // @ts-expect-error: Muted so we could enable TS strict mode
): boolean => {
  // Group detail fetching promises, resolved after reducing other recipient types
  const groupPromises: Promise<Group>[] = []

  // @ts-expect-error: Muted so we could enable TS strict mode
  const infoToCopy = recipients.reduce((acc, { type, entity }) => {
    if (type === 'person') {
      return [...acc, entity[infoType]]
    }

    if (type === 'email' || type === 'phoneNumber') {
      return [...acc, entity.value]
    }

    if (type === 'user') {
      return [...acc, entity.email]
    }

    if (type === 'group') {
      groupPromises.push(getGroupDetail(entity.id))
    }

    return acc
  }, [])

  Promise.all(groupPromises).then((res) => {
    res.forEach((group) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      group.contacts?.forEach((contact) => infoToCopy.push(contact[infoType]))
    })

    // @ts-expect-error: Muted so we could enable TS strict mode
    return infoToCopy.length > 0 && copy(uniq(infoToCopy).join(', '))
  })
}
