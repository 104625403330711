/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
export type UsersSearchListParams = {
  /**
   * Search query
   */
  q?: string
}

export type UsersImpersonationTargetsListParams = {
  /**
   * Exclude self targets from results.
   */
  exclude_self?: boolean
  /**
   * Only return results with user ids above it. Used for pagination.
   */
  last_id_user?: number
  /**
   * Only return results that are self targets.
   */
  only_self?: boolean
  /**
   * Number of results to return
   */
  page_size?: number
  /**
   * Filter results based on search term.
   */
  search?: string
}

export type UsersCurrencyListParams = {
  /**
   * Number of return entries
   */
  n?: number
  /**
   * Search query
   */
  q?: string
}

export type TrendsRetrieveParams = {
  /**
   * If cache should be used
   */
  check_cached?: number
  /**
   * Profile id to search for
   */
  profile_id: number
  /**
   * Additional search expression
   */
  search_term?: string
}

export type TagsReportsListParams = {
  /**
   * Limit.
   */
  limit?: number
  /**
   * Offset.
   */
  offset?: number
}

export type TagsListParams = {
  /**
   * Which field to use when ordering the results.
   */
  ordering?: string
}

export type SitesListParams = {
  /**
   * Search sites by country
   */
  country?: string
  /**
   * Url of the next page
   */
  cursor?: string
  /**
   * Agreement id (-1 means all Sites without an agreement)
   */
  id_agreement?: number
  /**
   * Search by site's name
   */
  name?: string
  /**
   * Page size
   */
  page_size?: number
}

export type ProfilesHistoryRetrieveParams = {
  /**
   * Convert searchline string to filters
   */
  with_query?: boolean
}

export type ProfilesDestroyParams = {
  /**
   * Force delete (1 - force delete, anything else - don't force delete)
   */
  force?: number
}

export type ProfilesRetrieveParams = {
  /**
   * Add history data to the response
   */
  with_history?: boolean
  /**
   * Convert searchline string to filters
   */
  with_query?: boolean
}

export type NotificationsListParams = {
  /**
   * Notifications with id greater than from_id
   */
  from_id?: number
  /**
   * Page number
   */
  page?: number
  /**
   * Page size
   */
  page_size?: number
}

export type InviteDirectListParams = {
  /**
   * Page number
   */
  page?: number
  /**
   * Email filter
   */
  q?: string
}

export type FoldersSharesTargetsSearchListParams = {
  /**
   * Maximum number of results to return
   */
  page_size?: number
  /**
   * Search term
   */
  q: string
}

export type AuthInviteSearchRetrieveParams = {
  page?: number
  page_size?: number
  q?: string
}

export type AuthInviteResendEmailCreateParams = {
  /**
   * token
   */
  token?: string
}

export type AlertsRecipientsListParams = {
  /**
   * Search for a specific alert sending
   */
  id_maillog?: string
}

export type AlertsNextPreviewCreateParams = {
  /**
   * Whether to send next alert preview in json format
   */
  json?: number
}

export type AlertsNextPreviewListParams = {
  /**
   * Whether to send next alert preview in json format
   */
  json?: number
}

export type AlertsHistoryResendCreateParams = {
  /**
   * Update timestamp
   */
  update_time?: boolean
}

export type AlertsHistoryRetrieveParams = {
  page?: number
}

export type AlertInfoRetrieveParams = {
  /**
   * Whether specified maillog has content in json format
   */
  json?: boolean
}

export type AgreementsListParams = {
  /**
   * Search for agreements by name or description
   */
  search_string?: string
}

export interface Updated {
  updated: number
}

export interface InviteDirectPostResponseChild {
  email: string
  status: InviteDirectPostResponseChildStatusEnum
}

export interface InviteDirectPostResponse {
  invites: InviteDirectPostResponseChild[]
  status: InviteDirectPostResponseStatusEnum
}

export interface InviteDirectPostRequest {
  alerts?: number[]
  email: string[]
  invitation_text?: string
  owner: number
}

export interface Data {
  data: string
}

export interface WatchQuery {
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  active: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  cnt: number
  readonly id: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  last_check: number
  query?: string
  readonly url: string
}

/**
 * * `1` - Visible when set
 * `2` - Always visible
 */
export type VisibilityEnum = (typeof VisibilityEnum)[keyof typeof VisibilityEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VisibilityEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

export interface VariableValuesChains {
  childvalueid: number
  readonly id: number
  parentvalueid: number
}

export interface VariableValues {
  /**
   * @minimum 0
   * @maximum 254
   */
  displayGroup: number
  readonly id: number
  isActive?: boolean
  /** @maxLength 511 */
  value: string
  variableId: number
}

export interface UserTypeField {
  name: string
  value: number
}

export interface UserRecipient {
  /** @nullable */
  email: string | null
  id_recipient: number
  in_previous_sending: boolean
  message: string
  /** @nullable */
  mobile: string | null
  /** @nullable */
  name: string | null
  status: MailSentStatus
  timestamp: number
  readonly type: string
  user_id: number
}

export interface UserMails {
  mails: MailList[]
  name: string
}

export interface UserInvitationSearch {
  count: number
  next: string
  previous: string
  results: InvitationSearch[]
}

export interface UUIDCommaSeparatedList {
  id: string
  name: string
}

export interface TrendResponse {
  /** The number of articles in each time_step */
  epoch_articles: number[]
  first_timestamp: number
  last_timestamp: number
  /** Number of seconds in each window the timeframe was divided into */
  time_step: number
  trends: TrendEntry[]
}

export interface TrendPhrase {
  display_name: string
  search_term: string
}

export interface TrendArticle {
  id_article: number
  id_site: number
  query_score: number
}

export interface TrendEntry {
  articles: TrendArticle[]
  /** The frequency rate in each window the timespan was divided into */
  frequencies: number[]
  phrase: TrendPhrase
  /** how many articles in each time_step is relevant for the trend */
  trend_articles: number[]
  trend_score: number
}

export interface TotalStatuses {
  address: string
  id_user: number
  timestamp: number
  username: string
}

export interface TotalStatusesPaginated {
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: TotalStatuses[]
}

export interface TopicUpdate {
  articlesCanBeApproved: boolean
  autoApprove: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  defaultTopicId?: number | null
  /** @nullable */
  deleted?: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  displayOrder: number
  readonly id: number
  isActive?: boolean
  /** @maxLength 255 */
  name: string
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  parentTopicId?: number | null
  reportId?: number
  scoringVariables?: ScoringVariables[]
}

export interface TopicScoringVariables {
  topicId: number
  variableId: number
}

/**
 * * `1` - ComboBox
 * `2` - AutoSuggestComboBox
 * `3` - ListBox
 * `4` - AutoSuggestListBox
 */
export type TopicDisplayTypeEnum = (typeof TopicDisplayTypeEnum)[keyof typeof TopicDisplayTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TopicDisplayTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const

export interface Topic {
  articlesCanBeApproved: boolean
  autoApprove: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  defaultTopicId?: number | null
  /** @nullable */
  deleted?: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  displayOrder: number
  readonly id: number
  isActive?: boolean
  /** @maxLength 255 */
  name: string
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  parentTopicId?: number | null
  reportId?: number
  startDate?: string
}

export interface TimeSchedulePeriod {
  end?: number
  interval?: number
  start: number
}

export interface TimeScheduleDayPeriod {
  friday?: TimeSchedulePeriod[]
  holidays?: TimeSchedulePeriod[]
  monday?: TimeSchedulePeriod[]
  saturday?: TimeSchedulePeriod[]
  sunday?: TimeSchedulePeriod[]
  thursday?: TimeSchedulePeriod[]
  tuesday?: TimeSchedulePeriod[]
  wednesday?: TimeSchedulePeriod[]
}

export interface TimeSchedulerRepresentation {
  country: CountryEnum
  timeConfiguration: TimeScheduleDayPeriod
  timeZone: string
}

export interface Template {
  readonly id: number
  name: string
  readonly readonly: boolean
  type: number
  readonly url: string
}

export interface TagsArticle {
  id_article: number
  id_site: number
  matchinfo?: string
  stimestamp: number
}

export interface TaggedArticleDetectionResponseArticles {
  id_article: number
  id_site: number
  /**
   * @minimum 0
   * @maximum 255
   */
  weight: number
}

export interface TaggedArticleDetectionResponse {
  articles: TaggedArticleDetectionResponseArticles[]
  id_basket: number
}

export interface TaggedArticleDetection {
  articles: ArticleVisited[]
  baskets: number[]
}

export interface TaggedArticle {
  has_articles: boolean
}

export interface TagSort {
  position: number
  tagId: number
}

/**
 * * `tagged` - tagged
 * `used` - used
 */
export type TagReportSelectionTypeEnum = (typeof TagReportSelectionTypeEnum)[keyof typeof TagReportSelectionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagReportSelectionTypeEnum = {
  tagged: 'tagged',
  used: 'used',
} as const

/**
 * Ways of selecting content from tags for reports.

Represented by a tagged union choosing between ways of selecting content,
either from an old report or all the unused content, or only some of the
unused articles.
 */
export interface TagReportSelection {
  from_timestamp?: number
  timestamp?: number
  to_timestamp?: number
  type: TagReportSelectionTypeEnum
}

export type TagReportTimestamp = number | string

export interface TagReport {
  count: number
  selection: TagReportSelection
  stimestampUsed: number
  readonly timestamp: TagReportTimestamp
  readonly timestampLastArt: number
}

/**
 * @nullable
 */
export type TagDetailStoredSearch = StoredSearchField | null

export type TagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export interface TagDetail {
  readonly access: TagDetailAccess
  readonly category: number
  /** @nullable */
  color: string | null
  /**
   * @maxLength 2
   * @nullable
   */
  combo?: string | null
  readonly feeds: UUIDCommaSeparatedList
  folder?: number
  readonly id: number
  readonly last_set: string
  readonly lastSort: string
  /**
   * Name of tag
   * @maxLength 255
   */
  name: string
  readonly new_position: number
  readonly owner: number
  readonly score: number
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: TagDetailStoredSearch
  type?: OneTwoEnum
  readonly url: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  visibility?: VisibilityEnum
}

export interface SupportIssue {
  /** @minimum 0 */
  context_id: number
  context_name: string
  context_type: string
  country: string
  description: string
  email?: string
  /** @minimum 0 */
  id_article: number
  /** @minimum 0 */
  id_site: number
  issue_type: IssueTypeEnum[]
  original_headline: string
  publication_date: number
  source: string
  translated_headline: string
}

/**
 * Serializer describing the SummarizerParamField
 */
export interface SummarizerParamField {
  max_number_of_words_to_show: number
  max_percentage_of_characters_to_show: number
  number_of_sentences: number
  type: number
}

export interface SummarizerParam {
  license: SummarizerParamField
  mail_license: SummarizerParamField
  report_license: SummarizerParamField
  share_license: SummarizerParamField
}

export interface StoredSearchField {
  active: number
  art_limit: number
  id: number
}

export interface StatusOverview {
  count: number
  status: number
}

export interface SourceOptionsPatchRequest {
  licence?: number
  licence_enforce?: number
  licence_enforce_mail?: number
  licence_enforce_report?: number
  licence_enforce_share?: number
  licence_mail?: number
  licence_report?: number
  licence_share?: number
}

export interface SourceDetail {
  readonly id_source: number
  licence: number
  licence_enforce: number
  licence_enforce_mail: number
  licence_enforce_report: number
  licence_enforce_share: number
  licence_mail: number
  licence_report: number
  licence_share: number
  readonly name: string
}

export interface Source {
  readonly id_source: number
  readonly name: string
}

/**
 * * `priority` - priority
 * `date` - date
 */
export type SortTypeEnum = (typeof SortTypeEnum)[keyof typeof SortTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortTypeEnum = {
  priority: 'priority',
  date: 'date',
} as const

/**
 * * `desc` - desc
 * `asc` - asc
 */
export type SortOrderEnum = (typeof SortOrderEnum)[keyof typeof SortOrderEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrderEnum = {
  desc: 'desc',
  asc: 'asc',
} as const

export interface SortTagContent {
  fromTimestamp?: number
  sortOrder?: SortOrderEnum
  sortType?: SortTypeEnum
  stimestampUsed?: number[]
  toTimestamp?: number
}

/**
 * @nullable
 */
export type SiteOptionsPatchRequestSummarizerParam = SummarizerParam | null

export interface SiteOptionsPatchRequest {
  /** @nullable */
  max_archive_age?: number | null
  /** @nullable */
  max_free_search_age?: number | null
  /** @nullable */
  summarizer_param?: SiteOptionsPatchRequestSummarizerParam
}

export interface SiteList {
  active_sections: number
  readonly country: string
  global_rank: number
  readonly id_site: number
  readonly languages: readonly string[]
  mediatype: number
  readonly name: string
  readonly sources: readonly Source[]
  url: string
}

export interface SitesListPaginated {
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: SiteList[]
}

/**
 * @nullable
 */
export type SiteDetailSummarizerParam = SummarizerParam | null

export interface SiteDetail {
  /** @nullable */
  readonly id_agreement: number | null
  id_site: number
  /** @nullable */
  max_archive_age: number | null
  /** @nullable */
  max_free_search_age: number | null
  readonly name: string
  readonly sources: readonly SourceDetail[]
  /** @nullable */
  summarizer_param: SiteDetailSummarizerParam
}

export interface SiteAndSourceDetailResponse {
  site_options: SiteDetail
  source_options: SourceDetail
}

export interface Site {
  readonly id_site: number
  readonly name: string
}

export interface SingleUserInvitePaginated {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: SingleUserInvitation[]
}

/**
 * * `0` - Failed
 * `1` - Pending
 * `2` - Accepted
 * `3` - Expired
 */
export type SingleUserInvitationStatusEnum =
  (typeof SingleUserInvitationStatusEnum)[keyof typeof SingleUserInvitationStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SingleUserInvitationStatusEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const

export interface SingleUserInvitation {
  /**
   * id of newly created user.
   * @nullable
   */
  created_user?: number | null
  /** @maxLength 255 */
  email: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  expires?: number
  readonly id: number
  /** @nullable */
  invitation_text?: string | null
  /** The parent user in the tree when user is created */
  owner: number
  /** @nullable */
  readonly reason: string | null
  readonly role: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  sent?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  status?: SingleUserInvitationStatusEnum
  /**
   * @minLength 43
   * @maxLength 43
   */
  token: string
  readonly usable: boolean
}

export interface SimpleUser {
  id_user: number
  readonly username: string
}

/**
 * * `and` - AND
 * `or` - OR
 * `near` - NEAR
 * `iql` - IQL
 */
export type SearchmodeEnum = (typeof SearchmodeEnum)[keyof typeof SearchmodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchmodeEnum = {
  and: 'and',
  or: 'or',
  near: 'near',
  iql: 'iql',
} as const

export interface SearchLineField {
  filters: Filters
  searchmode?: SearchmodeEnum
}

export interface ScoringVariables {
  /**
   * @minimum 0
   * @maximum 254
   */
  displayOrder: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  displayType?: DisplayTypeEnum
  readonly id: number
  isActive?: boolean
  /**
   * @minimum 1
   * @maximum 254
   */
  maxSelectedValues: number
  /**
   * @minimum 0
   * @maximum 254
   */
  minSelectedValues: number
  /** @maxLength 255 */
  name: string
  reportId: number
}

export interface ScoringVariableDetails {
  /**
   * @minimum 0
   * @maximum 254
   */
  displayOrder: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  displayType?: DisplayTypeEnum
  readonly id: number
  isActive: boolean
  /**
   * @minimum 1
   * @maximum 254
   */
  maxSelectedValues: number
  /**
   * @minimum 0
   * @maximum 254
   */
  minSelectedValues: number
  /** @maxLength 255 */
  name: string
  reportId: number
  values?: VariableValues[]
}

/**
 * * `3` - ThreeLevel
 * `5` - FiveLevel
 */
export type ScoringScaleEnum = (typeof ScoringScaleEnum)[keyof typeof ScoringScaleEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoringScaleEnum = {
  NUMBER_3: 3,
  NUMBER_5: 5,
} as const

export interface ScheduleItemDelay {
  id_item: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  ScheduledAt: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  SendAt: number
}

/**
 * * `person` - person
 * `group` - group
 * `user` - user
 */
export type SavedRecipientCreateTypeEnum =
  (typeof SavedRecipientCreateTypeEnum)[keyof typeof SavedRecipientCreateTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SavedRecipientCreateTypeEnum = {
  person: 'person',
  group: 'group',
  user: 'user',
} as const

export interface SavedRecipientCreate {
  id: number
  type: SavedRecipientCreateTypeEnum
}

export interface Response {
  token: string
}

export interface ResendEmailResponse {
  email_delivered: boolean
}

export interface ResendAlertResponse {
  emails: string[]
}

/**
 * * `email` - email
 * `mobile` - mobile
 * `person` - person
 * `group` - group
 * `user` - user
 */
export type ResendAlertRecipientsTypeEnum =
  (typeof ResendAlertRecipientsTypeEnum)[keyof typeof ResendAlertRecipientsTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResendAlertRecipientsTypeEnum = {
  email: 'email',
  mobile: 'mobile',
  person: 'person',
  group: 'group',
  user: 'user',
} as const

export interface ResendAlertRecipients {
  type: ResendAlertRecipientsTypeEnum
  value: string
}

export interface ResendAlertRequest {
  recipients: ResendAlertRecipients[]
}

export interface ReportUpdate {
  active?: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  categoryId: number
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id_user: number
  /** @maxLength 255 */
  name: string
  nPrinting: boolean
  /**
   * @minimum 0
   * @maximum 65535
   * @nullable
   */
  scoredFields?: number | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  scoringScale: ScoringScaleEnum
  /** @maxLength 255 */
  topicDisplayName?: string
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  topicDisplayType?: TopicDisplayTypeEnum
  /**
   * @minimum 1
   * @maximum 254
   */
  topicMaxSelectedValues: number
  /**
   * @minimum 0
   * @maximum 254
   */
  topicMinSelectedValues: number
}

export interface ReportStatistics {
  category: string
  id: number
  name: string
  uncalculated: number
  unprocessed: number
}

export interface ReportCatgeory {
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id: number
  /** @maxLength 255 */
  name: string
}

export interface Report {
  active?: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  categoryId: number
  /** @nullable */
  createDate?: string | null
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  readonly id: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id_user: number
  /** @maxLength 255 */
  name: string
  nPrinting: boolean
  /**
   * @minimum 0
   * @maximum 65535
   * @nullable
   */
  scoredFields?: number | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  scoringScale: ScoringScaleEnum
  /** @maxLength 255 */
  topicDisplayName?: string
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  topicDisplayType?: TopicDisplayTypeEnum
  /**
   * @minimum 1
   * @maximum 254
   */
  topicMaxSelectedValues?: number
  /**
   * @minimum 0
   * @maximum 254
   */
  topicMinSelectedValues?: number
  readonly topics: readonly Topic[]
}

export interface RemainingInvitesResponseOld {
  remaining_invites: number
}

export interface RemainingInvitesResponse {
  remaining_invites: number
  target_id: number
}

export interface RecipientMailList {
  id_mail: number
  owner_id: number
  owner_name: string
  status: number
  subject: string
  timestamp: number
}

export interface RecipientMails {
  mails: RecipientMailList[]
}

export type Recipient = DirectRecipient | ContactRecipient | UserRecipient | GroupRecipient

export interface ProfileWatchResponse {
  username: string
}

export interface ProfileWatchRequest {
  day?: number
  from_day?: number
  readership?: boolean
}

export interface ProfileUnpersistResponse {
  message: string
}

export interface ProfileSort {
  parent: number
  position?: number
  profileId: number
}

export interface ProfilePatch {
  blessed?: string
  folder?: number
  /** @maxLength 255 */
  name: string
  parent?: number
  /**
   * Time interval for trends. -1 for no trends, 0 for global time interval, or up to 10 000 seconds back in time.
   * @minimum -1
   */
  trend_interval: number
}

export interface ProfileItem {
  id?: number
  linemode: LinemodeEnum
  searchline: string
  searchmode: string
}

export interface ProfileHistoryItemsResponse {
  id_list: number
  items: SearchLineField
  /** @nullable */
  make_persistent: boolean | null
  name: string
  timestamp: number
}

export interface ProfileHistoryResponse {
  count: number
  results: ProfileHistoryItemsResponse[]
}

/**
 * @nullable
 */
export type ProfileDetailStoredSearch = StoredSearchField | null

export interface ProfileDetail {
  blessed?: string
  readonly category: number
  readonly est_search_impact: number
  folder?: number
  id?: number
  /** @nullable */
  readonly ifm_profile: number | null
  items?: ProfileItem[]
  /** @nullable */
  make_persistent?: boolean | null
  /** @maxLength 255 */
  name: string
  /** @nullable */
  readonly oldest_article: number | null
  readonly owner: number
  parent?: number
  /**
   * deprecated. see backfill_start
   * @minimum 0
   */
  persistent_backfill?: number
  /** @minimum 0 */
  persistent_backfill_end?: number
  persistent_backfill_grouping?: boolean
  /** @minimum 0 */
  persistent_backfill_start?: number
  persistent_grouping?: boolean
  /** @nullable */
  readonly persistent_profile: number | null
  readonly sm_phrases: string
  sm_rate?: MifluzRate[]
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: ProfileDetailStoredSearch
  /**
   * Time interval for trends. -1 for no trends, 0 for global time interval, or up to 10 000 seconds back in time.
   * @minimum -1
   * @maximum 10000
   */
  trend_interval?: number
  readonly type: number
  readonly url: string
}

export interface ProfileDependenciesResponse {
  alerts: AlertProfileDependencies[]
  feeds: FeedProfileDependencies[]
  parent: boolean
  profiles: number[]
}

export interface Profile {
  blessed?: string
  readonly category: number
  readonly est_search_impact: number
  readonly feeds: UUIDCommaSeparatedList
  readonly filtersUsed: string
  folder?: number
  readonly hasTrash: boolean
  id?: number
  readonly is_persistent: boolean
  /** @maxLength 255 */
  name: string
  readonly owner: number
  parent?: number
  readonly score: number
  /** @nullable */
  statistics?: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  trend_interval?: number
  readonly type: number
  readonly url: string
}

export interface PersistentError {
  message: string
}

export interface PersistentBackfill {
  end_time?: number
  start_time: number
}

export interface PatchedVariableValues {
  /**
   * @minimum 0
   * @maximum 254
   */
  displayGroup?: number
  readonly id?: number
  isActive?: boolean
  /** @maxLength 511 */
  value?: string
  variableId?: number
}

export interface PatchedTopicUpdate {
  articlesCanBeApproved?: boolean
  autoApprove?: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  defaultTopicId?: number | null
  /** @nullable */
  deleted?: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  displayOrder?: number
  readonly id?: number
  isActive?: boolean
  /** @maxLength 255 */
  name?: string
  /**
   * @minimum 0
   * @maximum 4294967295
   * @nullable
   */
  parentTopicId?: number | null
  reportId?: number
  scoringVariables?: ScoringVariables[]
}

/**
 * @nullable
 */
export type PatchedTagDetailStoredSearch = StoredSearchField | null

export type PatchedTagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export interface PatchedTagDetail {
  readonly access?: PatchedTagDetailAccess
  readonly category?: number
  /** @nullable */
  color?: string | null
  /**
   * @maxLength 2
   * @nullable
   */
  combo?: string | null
  readonly feeds?: UUIDCommaSeparatedList
  folder?: number
  readonly id?: number
  readonly last_set?: string
  readonly lastSort?: string
  /**
   * Name of tag
   * @maxLength 255
   */
  name?: string
  readonly new_position?: number
  readonly owner?: number
  readonly score?: number
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: PatchedTagDetailStoredSearch
  type?: OneTwoEnum
  readonly url?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  visibility?: VisibilityEnum
}

export interface PatchedSiteAndSourceDetailRequest {
  site_options?: SiteOptionsPatchRequest
  source_options?: SourceOptionsPatchRequest
}

export interface PatchedScoringVariableDetails {
  /**
   * @minimum 0
   * @maximum 254
   */
  displayOrder?: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  displayType?: DisplayTypeEnum
  readonly id?: number
  isActive?: boolean
  /**
   * @minimum 1
   * @maximum 254
   */
  maxSelectedValues?: number
  /**
   * @minimum 0
   * @maximum 254
   */
  minSelectedValues?: number
  /** @maxLength 255 */
  name?: string
  reportId?: number
  values?: VariableValues[]
}

export interface PatchedReportUpdate {
  active?: boolean
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  categoryId?: number
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id_user?: number
  /** @maxLength 255 */
  name?: string
  nPrinting?: boolean
  /**
   * @minimum 0
   * @maximum 65535
   * @nullable
   */
  scoredFields?: number | null
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  scoringScale?: ScoringScaleEnum
  /** @maxLength 255 */
  topicDisplayName?: string
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  topicDisplayType?: TopicDisplayTypeEnum
  /**
   * @minimum 1
   * @maximum 254
   */
  topicMaxSelectedValues?: number
  /**
   * @minimum 0
   * @maximum 254
   */
  topicMinSelectedValues?: number
}

export interface PatchedProfilePatch {
  blessed?: string
  folder?: number
  /** @maxLength 255 */
  name?: string
  parent?: number
  /**
   * How many seconds back in time we want to search for trends. -1 for no trends, 0 for global time interval
   * @minimum -1
   */
  trend_interval?: number
}

export interface PatchedMultiUserInvitation {
  active?: boolean
  readonly expires?: number
  readonly id?: number
  /** The parent user in the tree when user is created */
  owner?: number
  /** @nullable */
  readonly reason?: string | null
  readonly sent?: number
  token?: string
  url?: string
  readonly usable?: boolean
}

export interface PatchedDanishUser {
  readonly email?: string
  readonly id_user?: number
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean
  /** @nullable */
  readonly last_login?: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  owner?: number
  readonly username?: string
}

export interface PatchedCustomerDetail {
  readonly id?: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  /** Specifies if the user is owned by opoint or not */
  is_opoint?: boolean
  /**
   * @minimum 1
   * @nullable
   */
  max_num_users?: number | null
  /**
   * Customers name
   * @maxLength 255
   */
  name?: string
  /** Customers navision id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users?: number
  /** @minimum 0 */
  partner?: number
  /** Customers salesforce id */
  salesforce_id?: string
}

/**
 * @nullable
 */
export type PatchedAgreementSummarizerParam = SummarizerParam | null

export interface PatchedAgreement {
  readonly created?: string
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  readonly id_agreement?: number
  readonly last_change?: string
  licence?: number
  licence_enforce?: number
  licence_enforce_mail?: number
  licence_enforce_report?: number
  licence_enforce_share?: number
  licence_mail?: number
  licence_report?: number
  licence_share?: number
  /** @nullable */
  max_archive_age?: number | null
  /** @nullable */
  max_free_search_age?: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name?: string
  priority?: number
  sites?: unknown[]
  /** @nullable */
  summarizer_param?: PatchedAgreementSummarizerParam
}

export interface MailLog {
  readonly date: string
  readonly failed: boolean
  readonly has_json: boolean
  id: number
  maillog_id: number
  readonly opened: number
  readonly sent_to: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  stimestamp: number
}

export interface PaginatedMailLog {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: MailLog[]
}

export interface OpointUser {
  customer: string
  /**
   * @maxLength 255
   * @nullable
   */
  display_name?: string | null
  /**
   * Id from external Identity Provider
   * @maxLength 255
   */
  external_id?: string
  readonly id_user: number
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  owner?: number
  user_type?: UserTypeField
  /** @maxLength 255 */
  username: string
}

export interface OpointCustomer {
  readonly id_customer: number
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  partner: number
}

/**
 * * `1` - 1
 * `2` - 2
 */
export type OneTwoEnum = (typeof OneTwoEnum)[keyof typeof OneTwoEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OneTwoEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

export interface OTBRule {
  description?: string
  header?: string
  id_rule: number
  startvalue: number
}

export interface OTBList {
  description?: string
  header?: string
  id_rule: number
  startvalue: number
}

/**
 * @nullable
 */
export type NotificationFieldReportValue = Report | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ReportSerializer.
 */
export interface NotificationFieldReport {
  type: string
  /** @nullable */
  value: NotificationFieldReportValue
}

export type NotificationFieldValue = NotificationFieldExport | NotificationFieldReport

/**
 * @nullable
 */
export type PatchedNotificationObject = NotificationFieldValue | null

export interface PatchedNotification {
  readonly id?: number
  /** @nullable */
  object?: PatchedNotificationObject
  read?: boolean
  /** @maxLength 255 */
  title?: string
}

/**
 * @nullable
 */
export type NotificationFieldExportValue = Export | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ExportSerializer.
 */
export interface NotificationFieldExport {
  type: string
  /** @nullable */
  value: NotificationFieldExportValue
}

/**
 * @nullable
 */
export type NotificationObject = NotificationFieldValue | null

export interface Notification {
  readonly id: number
  /** @nullable */
  object?: NotificationObject
  read?: boolean
  /** @maxLength 255 */
  title: string
}

export interface NextMetadata {
  count: number
  nextPossibleSend?: string
  /** @nullable */
  type: number | null
}

export type NextAlertPreviewContent = { [key: string]: unknown }

export interface NextAlertPreview {
  content: NextAlertPreviewContent
  id_list: number
}

export interface MultiUserInvitation {
  active?: boolean
  readonly expires: number
  readonly id: number
  /** The parent user in the tree when user is created */
  owner: number
  /** @nullable */
  readonly reason: string | null
  readonly sent: number
  token?: string
  url?: string
  readonly usable: boolean
}

export interface MultiUserInvitePaginated {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: MultiUserInvitation[]
}

export interface MostImportantFails {
  alert: string
  score: number
  search_value: number
  user: string
}

export interface MifluzRate {
  readonly actual_rate: string
  /** @maxLength 3 */
  feed_id: string
  readonly feed_name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  maxrate: number
  readonly rate_used: string
}

export interface MapIFMProfilesResponse {
  IFMProfileName: number
}

export interface MailStats {
  domains_failed: number
  domains_total: number
  mails_failed: number
  mails_total: number
  recipients_failed: number
  recipients_total: number
  unix_hour: number
}

/**
 * * `Delivered` - Delivered
 * `Processing` - Processing
 * `Failed` - Failed
 * `Filtered` - Filtered
 * `Unknown` - Unknown
 */
export type MailSentStatus = (typeof MailSentStatus)[keyof typeof MailSentStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MailSentStatus = {
  Delivered: 'Delivered',
  Processing: 'Processing',
  Failed: 'Failed',
  Filtered: 'Filtered',
  Unknown: 'Unknown',
} as const

export interface MailLookup {
  searchval: string
  readonly type_field: string
  value: string
}

export interface MailList {
  id_mail: number
  status: number
  subject: string
  timestamp: number
}

/**
 * Serves for describing the /notifications response. forced_singular_serializer can't work with inline_serializer
 */
export interface ListNotifications {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: Notification[]
}

/**
 * * `R` - Required
 * `O` - Optional
 * `E` - Exclude
 */
export type LinemodeEnum = (typeof LinemodeEnum)[keyof typeof LinemodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinemodeEnum = {
  R: 'R',
  O: 'O',
  E: 'E',
} as const

export interface LicenseCategory {
  readonly id: number
  name: string
}

/**
 * @nullable
 */
export type LicenseCategoryProperty = LicenseCategory | null

export interface License {
  /** @nullable */
  category: LicenseCategoryProperty
  readonly description: string
  readonly label: string
  readonly name: string
  readonly num_users: number
  readonly value: number
}

export interface LastSortedResponse {
  timestamp: string
  /** @nullable */
  unixTimestamp: number | null
}

/**
 * * `1` - Factual mistake
 * `2` - Translation error
 * `3` - Metadata error
 * `4` - Other issue
 */
export type IssueTypeEnum = (typeof IssueTypeEnum)[keyof typeof IssueTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const

/**
 * * `SUCCESS` - SUCCESS
 * `PARTIAL` - PARTIAL
 * `FAILED` - FAILED
 */
export type InviteDirectPostResponseStatusEnum =
  (typeof InviteDirectPostResponseStatusEnum)[keyof typeof InviteDirectPostResponseStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteDirectPostResponseStatusEnum = {
  SUCCESS: 'SUCCESS',
  PARTIAL: 'PARTIAL',
  FAILED: 'FAILED',
} as const

/**
 * * `SENT` - SENT
 * `ALREADY_INVITED` - ALREADY_INVITED
 * `FAILED` - FAILED
 */
export type InviteDirectPostResponseChildStatusEnum =
  (typeof InviteDirectPostResponseChildStatusEnum)[keyof typeof InviteDirectPostResponseChildStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteDirectPostResponseChildStatusEnum = {
  SENT: 'SENT',
  ALREADY_INVITED: 'ALREADY_INVITED',
  FAILED: 'FAILED',
} as const

/**
 * drf-spectacular picks up both possible types for the 'id' field this way
 * @nullable
 */
export type InvitationSearchId = number | string | null

export interface InvitationSearch {
  email?: string
  /**
   * drf-spectacular picks up both possible types for the 'id' field this way
   * @nullable
   */
  readonly id: InvitationSearchId
  is_active?: boolean
  is_user: boolean
  last_login?: string
  name: string
  role?: string
}

export interface ImpersonationTarget {
  customer: OpointCustomer
  /** User id of impersonation target. */
  id_user: number
  /** If the target is of self type, meaning it represents the logged in user in another context. */
  self: boolean
  /** Username of impersonation target. */
  username: string
}

export interface IdSiteList {
  id_sites: number[]
}

export interface IFMOrganizationProfileNumbers {
  profiles: number[]
}

export interface IFMLoginUser {
  archive_length: number
  create_date: number
  login: string
  name?: string
  profiles: number[]
  /**
   * @minLength 36
   * @maxLength 36
   */
  uuid: string
}

export interface IFMLoginRights {
  alerts: boolean
  is_organization_admin: boolean
  media_report: boolean
  multimedia: boolean
  print: boolean
  share: boolean
  statistics: boolean
}

export interface IFMLoginOrganization {
  /** @minimum 0 */
  debitor_id: number
  name: string
  /**
   * @minLength 36
   * @maxLength 36
   */
  uuid: string
}

export interface IFMLogin {
  organization: IFMLoginOrganization
  remember_me?: boolean
  rights: IFMLoginRights
  user: IFMLoginUser
}

export interface GroupedEventMessage {
  messages: EventMessage[]
}

export interface GroupRecipientChild {
  /** @nullable */
  email: string | null
  id_contact: number
  in_previous_sending: boolean
  message: string
  /** @nullable */
  mobile: string | null
  /** @nullable */
  name: string | null
  status: MailSentStatus
  timestamp: number
}

export interface GroupRecipient {
  contacts: GroupRecipientChild[]
  group_id: number
  id_recipient: number
  name: string
  readonly type: string
}

export interface ForumUser {
  readonly id: number
  /** @maxLength 60 */
  username: string
}

export interface ForumTag {
  readonly created_at: string
  /**
   * @maxLength 1000
   * @nullable
   */
  description?: string | null
  readonly id: number
  /** @maxLength 255 */
  name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  pm_topic_count?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  public_topic_count?: number
  /** @nullable */
  target_tag?: number | null
  readonly topics: readonly number[]
  readonly updated_at: string
}

export interface ForumPost {
  cooked: string
  readonly created_at: string
  readonly id: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  post_number: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  post_type: number
  topic: number
  user: ForumUser
}

export interface ForumParentCategory {
  /** @maxLength 6 */
  color?: string
  readonly id: number
  /** @maxLength 50 */
  name: string
}

export interface ForumCategory {
  /** @maxLength 6 */
  color?: string
  readonly created_at: string
  /** @nullable */
  description?: string | null
  readonly id: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   * @nullable
   */
  latest_post_id?: number | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   * @nullable
   */
  latest_topic_id?: number | null
  /** @maxLength 50 */
  name: string
  readonly parent_category: ForumParentCategory
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   * @nullable
   */
  position?: number | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  post_count?: number
  /** @maxLength 255 */
  slug: string
  /** @maxLength 6 */
  text_color?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  topic_count?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   * @nullable
   */
  topic_id?: number | null
  readonly updated_at: string
  user: ForumUser
}

export interface ForumTopic {
  /** @nullable */
  readonly assigned_to: string | null
  category: ForumCategory
  closed?: boolean
  readonly created_at: string
  /**
   * @maxLength 255
   * @nullable
   */
  excerpt?: string | null
  readonly id: number
  last_post_user: number
  /** @nullable */
  readonly last_posted_at: string | null
  readonly name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  participant_count?: number
  readonly posts_count: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  reply_count?: number
  /**
   * @maxLength 255
   * @nullable
   */
  slug?: string | null
  tags: ForumTag[]
  /** @maxLength 255 */
  title: string
  readonly updated_at: string
  user: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  views?: number
}

export interface ForumTopicDetail {
  /** @nullable */
  readonly assigned_to: string | null
  category: ForumCategory
  closed?: boolean
  readonly created_at: string
  /**
   * @maxLength 255
   * @nullable
   */
  excerpt?: string | null
  readonly id: number
  last_post_user: ForumUser
  /** @nullable */
  readonly last_posted_at: string | null
  readonly name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  participant_count?: number
  posts: ForumPost[]
  readonly posts_count: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  reply_count?: number
  /**
   * @maxLength 255
   * @nullable
   */
  slug?: string | null
  sub_topics: ForumTopic[]
  tags: ForumTag[]
  /** @maxLength 255 */
  title: string
  readonly updated_at: string
  user: ForumUser
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  views?: number
}

/**
 * * `0` - Profile
 * `1` - Tag
 * `2` - Sentiment tag
 * `6` - Saved statistic
 */
export type FolderViewCreateTypeEnum = (typeof FolderViewCreateTypeEnum)[keyof typeof FolderViewCreateTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FolderViewCreateTypeEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_6: 6,
} as const

export interface FolderViewCreate {
  category?: number
  /** @maxLength 256 */
  description?: string
  /** @maxLength 256 */
  icon?: string
  readonly id: number
  /** @maxLength 256 */
  name: string
  readonly owner: SimpleUser
  shares?: FolderShareConfig[]
  traits?: number
  type: FolderViewCreateTypeEnum
}

export interface FolderView {
  category?: number
  /** @maxLength 256 */
  description?: string
  /** @maxLength 256 */
  icon?: string
  readonly id: number
  /** @maxLength 256 */
  name: string
  readonly owner: SimpleUser
  traits?: number
  readonly type: number
}

export interface FolderSortRequestBody {
  /** Id of folder to change sort position of. */
  id: number
  /** Desired position of folder, 0-based. */
  position: number
}

/**
 * * `none` - none
 * `only` - only
 */
export type FolderShareConfigFilterTypeEnum =
  (typeof FolderShareConfigFilterTypeEnum)[keyof typeof FolderShareConfigFilterTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FolderShareConfigFilterTypeEnum = {
  none: 'none',
  only: 'only',
} as const

export interface FolderShareConfigFilterId {
  id: number
}

export interface FolderShareConfigFilter {
  items?: FolderShareConfigFilterId[]
  type: FolderShareConfigFilterTypeEnum
}

export interface FolderShareConfigAccess {
  delete_folder?: boolean
  edit_folder?: boolean
  tag_articles?: boolean
}

export interface FolderShareConfig {
  access: FolderShareConfigAccess
  filter?: FolderShareConfigFilter
  inherit: boolean
  user: SimpleUser
}

export interface Filters {
  id?: string
  index?: number
  name?: string
  type: string
}

export interface FeedProfileDependencies {
  id: number
  name: string
}

/**
 * Serializer for returning statistics export data from notifications
 */
export interface Export {
  readonly articles: number
  readonly download: string
  id: number
  readonly status: number
  timeFinished: number
  readonly timestampFrom: number
  readonly timestampTo: number
  title: string
  readonly type: number
}

export interface EventMessage {
  message: string
  timestamp: number
}

export interface Error {
  details: string
}

/**
 * * `1` - ComboBox
 * `2` - AutoSuggestComboBox
 * `3` - ListBox
 * `4` - AutoSuggestListBox
 */
export type DisplayTypeEnum = (typeof DisplayTypeEnum)[keyof typeof DisplayTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DisplayTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const

/**
 * * `email` - email
 * `mobile` - mobile
 */
export type DirectRecipientCreateTypeEnum =
  (typeof DirectRecipientCreateTypeEnum)[keyof typeof DirectRecipientCreateTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectRecipientCreateTypeEnum = {
  email: 'email',
  mobile: 'mobile',
} as const

export interface DirectRecipientCreate {
  type: DirectRecipientCreateTypeEnum
  value: string
}

export interface DirectRecipient {
  id_recipient: number
  in_previous_sending: boolean
  message: string
  status: MailSentStatus
  timestamp: number
  type: string
  value: string
}

export interface DeletedProfilesItemsResponse {
  id_list: number
  items: ProfileItem[]
  /** @nullable */
  make_persistent: boolean | null
  name: string
  timestamp: number
}

export interface DeletedProfilesResponse {
  count: number
  results: DeletedProfilesItemsResponse[]
}

export interface DeleteTagResponse {
  data: string
  message: string
}

export interface DeleteProfileResponse400 {
  data: string
  message: string
}

export interface DanishUser {
  readonly email: string
  readonly id_user: number
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean
  /** @nullable */
  readonly last_login: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  owner?: number
  readonly username: string
}

export interface CustomerDetail {
  readonly id: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  /** Specifies if the user is owned by opoint or not */
  is_opoint?: boolean
  /**
   * @minimum 1
   * @nullable
   */
  max_num_users: number | null
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /** Customers navision id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users: number
  /** @minimum 0 */
  partner: number
  /** Customers salesforce id */
  salesforce_id?: string
}

export interface Customer {
  readonly id: number
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /** @minimum 0 */
  partner: number
}

export interface Currency {
  /** @maxLength 3 */
  currency: string
  /** @maxLength 255 */
  name: string
}

export type CreateRecipient = DirectRecipientCreate | SavedRecipientCreate

/**
 * * `GB_EN` - GB_EN
 * `SE` - SE
 * `NO` - NO
 * `DK` - DK
 * `FI` - FI
 * `ET` - ET
 */
export type CountryEnum = (typeof CountryEnum)[keyof typeof CountryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryEnum = {
  GB_EN: 'GB_EN',
  SE: 'SE',
  NO: 'NO',
  DK: 'DK',
  FI: 'FI',
  ET: 'ET',
} as const

export interface ContactRecipient {
  /** @nullable */
  email: string | null
  id_contact: number
  id_recipient: number
  in_previous_sending: boolean
  message: string
  /** @nullable */
  mobile: string | null
  /** @nullable */
  name: string | null
  status: MailSentStatus
  timestamp: number
  readonly type: string
}

export interface CodingEvent {
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  actionId: number
  readonly id: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id_article: number
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  id_site: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  id_user: number
  /** @maxLength 255 */
  machineName: string
  timestamp: string
  /**
   * @minimum 0
   * @maximum 4294967295
   */
  topicId: number
}

export interface BaseArticle {
  id_article: number
  id_site: number
}

export interface AsyncReportCreateResponse {
  new_timestamp?: number
  report_id: number
}

export interface Articles {
  articles: Article[]
}

export interface ArticleVisited {
  action?: ActionEnum[]
  id_article: number
  id_site: number
}

export interface ArticleReference {
  articleType: string
  author: string
  comment: string
  dominance: number
  estimatedReadership: number
  header: string
  /** @nullable */
  headline: string | null
  hitArticle: number
  hitHeadline: number
  hitPicture: number
  id_article: number
  id_site: number
  insertDate: string
  mediatype: string
  scoredAt: string
  scoredBy: number
  scoredValues: number[]
  sentiment: number
  siteName: string
  sourceLang: string
  sourceName: string
  sourceUrl: string
  status: string
  summary: string
  swot: string
  timestamp: string
  topicId: number
  wordCount: number
}

export interface ArticleBasketDelete {
  articles: BaseArticle[]
}

export interface ArticleBasket {
  articles: TagsArticle[]
  weight: number
}

export interface Article {
  id_article: number
  id_site: number
  matchinfo?: string
  stimestamp?: number
  xmltext?: string
}

export interface AnalyticsQuery {
  readonly id_item: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  last_set?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_rate?: number
  rate?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  rate_last_updated?: number
  readonly url: string
}

export interface AnalyticQueryItem {
  id?: number
  linemode: LinemodeEnum
  searchline: string
  searchmode: string
}

export interface AnalyticQueryDetails {
  readonly id_item: number
  items?: AnalyticQueryItem[]
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  last_set?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_rate?: number
  rate?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  rate_last_updated?: number
  readonly topic_name: string
  readonly url: string
}

/**
 * * `4` - Email
 * `6` - FTP
 * `30` - Stored search
 * `7` - Feed
 * `42` - Update search
 * `50` - Persistent
 */
export type AlertType = (typeof AlertType)[keyof typeof AlertType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertType = {
  NUMBER_4: 4,
  NUMBER_6: 6,
  NUMBER_30: 30,
  NUMBER_7: 7,
  NUMBER_42: 42,
  NUMBER_50: 50,
} as const

export interface AlertTag {
  id: number
  name: string
  readonly type: string
  readonly url: string
}

export interface AlertSubscriptionDetail {
  /** @minimum 1 */
  alert_id: number
  is_mail?: boolean
  subscribe: boolean
}

export interface AlertSubscription {
  id: number
  is_subscribed: boolean
  name: string
}

/**
 * * `0` - Mail
 * `1` - Phone
 * `2` - Contact mail
 * `3` - Contact phone
 * `4` - Group mail
 * `5` - Group phone
 * `6` - User mail
 * `7` - User phone
 * `8` - Push iOS
 * `9` - Push Android
 */
export type AlertRecipientTypeEnum = (typeof AlertRecipientTypeEnum)[keyof typeof AlertRecipientTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertRecipientTypeEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
} as const

export interface AlertRecipientOverview {
  contacts: AlertContact[]
  direct: AlertContact[]
  groups: AlertGroupContact[]
  users: AlertContact[]
}

export interface AlertRecipient {
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  alert: number
  filter: boolean
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  id_recipient: number
  /** @maxLength 255 */
  literal?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  type: AlertRecipientTypeEnum
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  value?: number
}

export interface AlertProfileDependencies {
  id: number
  name: string
}

export interface AlertProfile {
  id: number
  name: string
  readonly type: string
  readonly url: string
}

export type AlertProfilesAndTags = AlertProfile | AlertTag

export interface AlertMails {
  alert_name: string
  mails: MailList[]
  owner_id: number
  owner_name: string
}

/**
 * @nullable
 */
export type AlertHistoryContentContent = { [key: string]: unknown } | null

export interface AlertHistoryContent {
  /** @nullable */
  content: AlertHistoryContentContent
  id_list: number
  name: string
}

export interface AlertHistoryItem {
  content: AlertHistoryContent[]
}

export interface AlertGroupContact {
  contacts: AlertContact[]
  /** @nullable */
  name: string | null
}

export interface AlertDetail {
  active: number
  readonly baskets: readonly AlertBasket[]
  readonly editable: boolean
  readonly groupidentical: boolean
  readonly id: number
  readonly is_org: boolean
  readonly isCreatedFromApp: boolean
  readonly lastSent: number
  locale?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  readonly profiles: readonly AlertProfilesAndTags[]
  readonly pushRecipients: readonly AlertRecipient[]
  readonly recipients: readonly AlertRecipient[]
  readonly resendidentical: boolean
  readonly schedule: TimeSchedulerRepresentation
  readonly sender: string
  readonly smsRecipients: readonly AlertRecipient[]
  /** @maxLength 255 */
  subject?: string
  readonly template: Template
  readonly translate: boolean
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  type: AlertType
  readonly url: string
}

export interface AlertCreate {
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  active?: number
  /** @nullable */
  articleMaxTimestamp?: number | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  editable?: number
  readonly id: number
  /** @nullable */
  includeIdentical?: boolean | null
  is_org?: boolean
  locale?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  nextPossibleSend?: number
  profiles: AlertProfile[]
  recipients: CreateRecipient[]
  resendidentical?: boolean
  schedule: TimeSchedulerRepresentation
  sender?: string
  smsRecipients?: CreateRecipient[]
  /** @maxLength 255 */
  subject?: string
  templateId: number
  readonly url: string
}

export interface AlertCountResponse {
  count: number
}

export interface AlertContactOverview {
  id: number
  recipients: AlertRecipientOverview
  subject: string
}

export interface AlertContact {
  /** @nullable */
  email: string | null
  /** @nullable */
  mobile: string | null
  /** @nullable */
  name: string | null
}

export interface AlertBasket {
  readonly id: number
  readonly name: string
}

export interface Alert {
  active: number
  readonly baskets: readonly AlertBasket[]
  readonly editable: boolean
  readonly has_push_subscription: string
  readonly id: number
  readonly isCreatedFromApp: boolean
  readonly lastSent: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  readonly schedule: TimeSchedulerRepresentation
  /** @maxLength 255 */
  subject?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  type: AlertType
  readonly url: string
}

/**
 * @nullable
 */
export type AgreementCreateSummarizerParam = SummarizerParam | null

export interface AgreementCreate {
  readonly created: string
  /**
   * @maxLength 255
   * @nullable
   */
  description?: string | null
  readonly id_agreement: number
  readonly last_change: string
  licence?: number
  licence_enforce?: number
  licence_enforce_mail?: number
  licence_enforce_report?: number
  licence_enforce_share?: number
  licence_mail?: number
  licence_report?: number
  licence_share?: number
  /** @nullable */
  max_archive_age?: number | null
  /** @nullable */
  max_free_search_age?: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name: string
  priority?: number
  sites?: unknown[]
  /** @nullable */
  summarizer_param?: AgreementCreateSummarizerParam
}

/**
 * @nullable
 */
export type AgreementSummarizerParam = SummarizerParam | null

export interface Agreement {
  readonly created: string
  /**
   * @maxLength 255
   * @nullable
   */
  description: string | null
  readonly id_agreement: number
  readonly last_change: string
  licence: number
  licence_enforce: number
  licence_enforce_mail: number
  licence_enforce_report: number
  licence_enforce_share: number
  licence_mail: number
  licence_report: number
  licence_share: number
  /** @nullable */
  max_archive_age: number | null
  /** @nullable */
  max_free_search_age: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name: string
  priority: number
  sites: unknown[]
  /** @nullable */
  summarizer_param: AgreementSummarizerParam
}

export interface AggregatedRange {
  statuses: StatusOverview[]
}

/**
 * * `2` - Article consumed
 * `3` - Article viewed for 3 sec
 * `4` - Article viewed for 10 sec
 * `5` - Article clicked
 * `6` - PDF viewed
 * `7` - Media clip played
 * `8` - Image clicked
 * `9` - Article added to tag
 * `10` - Entry fetched from Go log
 */
export type ActionEnum = (typeof ActionEnum)[keyof typeof ActionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionEnum = {
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
} as const
