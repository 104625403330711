import { getUnixTime } from 'date-fns'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, defer, EMPTY, from, interval, merge, of } from 'rxjs'
import { catchError, delay, filter, map, retry, switchMap, take, takeUntil } from 'rxjs/operators'
import { ArticleTag, extractIdenticalArticles } from '@opoint/infomedia-storybook'

import { t } from 'i18next'
import { evolve, map as mapR, find as findR, path, propEq } from 'ramda'
import { AppActions } from '../actions'
import { SaveAlertValidFailureAction } from '../actions/alerts'
import { NotificationSocketSuccessAction } from '../actions/notifications'
import { ProfilesFetchSuccessAction } from '../actions/profiles'
import {
  ReportsArticlesAction,
  ReportsArticlesFailureAction,
  ReportsArticlesSuccessAction,
  ReportsCreateAction,
  ReportsCreateFailureAction,
  ReportsCreateIsTakingTooLongAction,
  ReportsCreateSuccessAction,
  ReportsHistoryFetchAction,
  ReportsHistoryFetchFailureAction,
  ReportsHistoryFetchSuccessAction,
  ReportsLoadInitialArticlesAction,
  ReportsModalCloseAction,
  ReportsResetAction,
  ReportsShareFailureAction,
  ReportsShareSuccessAction,
  ReportsSourceAction,
  ReportsStopStatusCheckingAction,
  ReportsTemplateAction,
  ReportsTitleAction,
  ReportsUpdateReportIdAction,
  ReportsUpdateSomeMetaAction,
  ReportsValidShareFailureAction,
  ShareReportAction,
  ShareReportUpdateStepAction,
  UpdatedSomeMetaDataFailureAction,
  UpdatedSoMeMetaDataSuccessAction,
} from '../actions/reports'
import { DeleteReportAction, DeleteReportFailureAction, DeleteReportSuccessAction } from '../actions/reportsHistory'
import { TagsFetchSuccessAction } from '../actions/tags'
import { TemplatesFetchSuccessAction } from '../actions/templates'
import { ReportsModalOpenAction } from '../actions/ui'
import { ReportObject } from '../components/types/reports'
import { includesDisallowedEmailRecipients } from '../helpers/contacts'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import { Notification, SearchItem } from '../opoint/flow'
import {
  checkReportStatus,
  createReport,
  deleteReport,
  getReportsTagHistory,
  REPORT_STEP_CREATE_REPORT,
  shareReport,
} from '../opoint/reports'
import { search, searchTimestamps } from '../opoint/search'
import { lastSort } from '../opoint/tags'
import { RootState } from '../reducers'
import { getSelectedArticles } from '../selectors/articlesSelectors'
import { getReportContacts } from '../selectors/contactSelectors'
import { getProfileById } from '../selectors/profilesSelectors'
import {
  getAllSortedIDs,
  getAutoTranslate,
  getCheckedTimestamps,
  getDeletedArticles,
  getEndDate,
  getFooter,
  getIsXLSTemplate,
  getPreface,
  getReportHistoryObject,
  getReportId,
  getReportObject,
  getSearchItems,
  getShareData,
  getSortedIDs,
  getSource,
  getStartDate,
  getTemplate,
  getTitle,
  getUpdateSoMe,
  useReportHistory,
} from '../selectors/reportsSelector'
import { getMainSearchLine } from '../selectors/searchSelectors'
import {
  getAllowedDomains,
  getAutoTranslateSearchParams,
  getOpointLocale,
  getSimilarArticlesVisible,
} from '../selectors/settingsSelectors'
import { getTagById } from '../selectors/tagsComposedSelectors'
import { getDefaultTemplate, getTemplateModules } from '../selectors/templatesSelectors'
import { isReportModalOpen } from '../selectors/uiSelectors'
import { M360Article } from '../opoint/articles/types'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import { throwErrorOnSearchdFailure } from './searchEpics'

const reportsSourceEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReportsSourceAction>('REPORTS_SOURCE'),
    filter(({ payload }) => payload.type === 'tag'),
    map(({ payload }) => {
      return { type: 'REPORTS_HISTORY_FETCH', payload } as ReportsHistoryFetchAction
    }),
  )

const reportsTitleEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  combineLatest([
    action$.pipe(ofType<AppActions, ReportsSourceAction>('REPORTS_SOURCE')),
    action$.pipe(ofType<AppActions, TagsFetchSuccessAction>('TAGS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, ProfilesFetchSuccessAction>('PROFILES_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    map(
      ([
        {
          payload: { type, id },
        },
      ]) => {
        let title = t('Report')
        switch (type) {
          case 'tag':
            // @ts-expect-error: Muted so we could enable TS strict mode
            title = getTagById(id)(state$.value).name
            break
          case 'profile': {
            const profileName = getProfileById(id)(state$.value)?.name
            title = profileName ?? title
            break
          }
          case 'current_search':
            title = t('Current search')
            break
          case 'selected_articles':
            title = t('Selected articles')
            break
          default:
            title = t('Report') // todo
        }

        return { type: 'REPORTS_TITLE', payload: { title } } as ReportsTitleAction
      },
    ),
  )

const reportsOpenModalEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, ReportsModalOpenAction>('REPORTS_MODAL_OPEN')),
    action$.pipe(ofType<AppActions, TemplatesFetchSuccessAction>('TEMPLATES_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(() => {
      const defaultTemplate = getDefaultTemplate(state$.value)

      return of<ReportsTemplateAction>({
        type: 'REPORTS_TEMPLATE',
        payload: { templateId: defaultTemplate?.id },
      })
    }),
  )

const reportUpdateSoMeMetadata = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ReportsCreateAction>('REPORTS_CREATE'),
    switchMap(({ payload: { groupidentical, updatedSoMe, updateError } }) => {
      const state = state$.value
      const updateSoMe = getUpdateSoMe(state)
      const searchline = getMainSearchLine(state)
      const selectedArticles = map(
        (article: M360Article) => ({
          id_article: article.id_article,
          id_site: article.id_site,
          time: new Date(article.unix_timestamp * 1000),
        }),
        getSelectedArticles(state),
      )

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }

      if (!updateSoMe || updatedSoMe || updateError) {
        return EMPTY
      }

      const params = { templateId: getTemplate({ reports: { template: state.reports.template } }) }
      const searchParams = {
        groupidentical,
        newest: getUnixTime(new Date(getEndDate(state))),
        oldest: getUnixTime(new Date(getStartDate(state))),
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
        allmeta: true,
        update_social_meta: updateSoMe,
        update_social_meta_max_wait: 0,
        requestedarticles: 60000,
        articles: [],
      }

      if (selectedArticles.length > 0) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        searchParams.articles = selectedArticles
      }

      // In order to update SoMe metadata, we're doing two search requests.
      // First one to start the update of all articles in the set
      // Second one to get the article set, with the updated data.
      return of(
        search(
          // @ts-expect-error: Muted so we could enable TS strict mode
          selectedArticles.length > 0 ? [requiredSearchItem] : getSearchItems(state),
          searchParams,
          params,
          getOpointLocale(state),
        ),
      ).pipe(
        delay(3000),
        switchMap(() =>
          from(
            search(
              // @ts-expect-error: Muted so we could enable TS strict mode
              selectedArticles.length > 0 ? [requiredSearchItem] : getSearchItems(state),
              searchParams,
              params,
              getOpointLocale(state),
            ),
          ).pipe(
            switchMap(throwErrorOnSearchdFailure),
            switchMap((response) =>
              of<UpdatedSoMeMetaDataSuccessAction | ReportsUpdateSomeMetaAction | ReportsCreateAction>(
                {
                  type: 'UPDATED_SOME_META_DATA_SUCCESS',
                  payload: { response },
                },
                {
                  type: 'REPORTS_UPDATE_SOME_META',
                  payload: { updateSoMe: false },
                },
                {
                  type: 'REPORTS_CREATE',
                  payload: { groupidentical, updatedSoMe: true },
                },
              ),
            ),
            catchError(logOutOnExpiredToken),
            catchError(() =>
              of<UpdatedSomeMetaDataFailureAction | ReportsUpdateSomeMetaAction | ReportsCreateAction>(
                { type: 'UPDATED_SOME_META_DATA_FAILURE' },
                {
                  type: 'REPORTS_UPDATE_SOME_META',
                  payload: { updateSoMe: false },
                },
                {
                  type: 'REPORTS_CREATE',
                  payload: { groupidentical, updatedSoMe: false, updateError: true },
                },
              ),
            ),
          ),
        ),
      )
    }),
  )

const reportsLoadEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, ReportsArticlesAction>('REPORTS_ARTICLES'),
    switchMap(({ payload: { context, groupidentical } }) => {
      const state = state$.value

      const params = { templateId: getTemplate({ reports: { template: state.reports.template } }) }
      const searchParams = {
        context,
        groupidentical,
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
      }
      const { type, id } = getSource(state) || {}

      if (!type) {
        return EMPTY
      }

      let backendData$
      switch (type) {
        case 'tag':
        case 'profile':
        case 'current_search':
          backendData$ = defer(() =>
            useReportHistory(state)
              ? // tag using history (timestamps)
                searchTimestamps(
                  {
                    tagId: id,
                    // @ts-expect-error: Muted so we could enable TS strict mode
                    stimestamps: getCheckedTimestamps(state),
                  },
                  { ...searchParams, requestedarticles: 300 },
                  params,
                )
              : // tag or profile (current_search) by date
                search(
                  // @ts-expect-error: Muted so we could enable TS strict mode
                  getSearchItems(state),
                  {
                    ...searchParams,
                    newest: getUnixTime(new Date(getEndDate(state))),
                    oldest: getUnixTime(new Date(getStartDate(state))),
                    requestedarticles: type === 'tag' ? 300 : 30,
                  },
                  params,
                  getOpointLocale(state),
                ),
          )
          break
        case 'selected_articles': {
          const groupedArticles = getSelectedArticles(state)

          backendData$ = of({
            searchresult: {
              document: groupidentical ? groupedArticles : extractIdenticalArticles(groupedArticles),
            },
            alreadyPreprocessed: true,
          })
          break
        }
        default:
          console.error('Wrong type!') /* eslint-disable-line no-console */
      }

      if (type === 'tag') {
        // set mark that for newer articles then last sorted timestamp
        // this should be IMHO at the API side
        const lastSorted$ = defer(() => lastSort(id))

        return combineLatest([lastSorted$, backendData$]).pipe(
          // @ts-expect-error: Muted so we could enable TS strict mode
          switchMap(([{ unixTimestamp }, { searchresult }]) => {
            const isNew = (a) => {
              // @ts-expect-error: Muted so we could enable TS strict mode
              const tags: ArticleTag[] = path(['tags'], a)
              if (!tags) {
                return false
              }

              // @ts-expect-error: Muted so we could enable TS strict mode
              const set = findR(propEq('id', id))(tags)?.set

              return OpointTimestampToTimestamp(set) > unixTimestamp
            }

            return of<ReportsArticlesSuccessAction>({
              type: 'REPORTS_ARTICLES_SUCCESS',
              payload: {
                searchresult: evolve(
                  {
                    document: mapR((document: M360Article) => ({
                      groupId: document.sort_group_ref,
                      newSinceLastSorted: isNew(document),
                      document,
                    })),
                  },
                  searchresult,
                ),
              },
            })
          }),
        )
      }

      return backendData$?.pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map(({ searchresult, alreadyPreprocessed }) => {
          return {
            type: 'REPORTS_ARTICLES_SUCCESS',
            payload: {
              searchresult: evolve(
                {
                  document: mapR((document: M360Article) => ({
                    groupId: document.sort_group_ref,
                    document,
                  })),
                },
                searchresult,
              ),
              alreadyPreprocessed, // articles
            },
          } as ReportsArticlesSuccessAction
        }),

        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((error) =>
          of<ReportsArticlesFailureAction>({ type: 'REPORTS_ARTICLES_FAILURE', payload: { error } }),
        ),
      )
    }),
  )

const reportLoadInitialEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReportsLoadInitialArticlesAction>('REPORTS_LOAD_INITIAL_ARTICLES'),
    switchMap(({ payload: { groupidentical } }) =>
      of<ReportsArticlesAction>({ type: 'REPORTS_ARTICLES', payload: { groupidentical } }),
    ),
  )

const reportsHistoryEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReportsHistoryFetchAction>('REPORTS_HISTORY_FETCH'),
    switchMap(({ payload: { id } }) =>
      from(getReportsTagHistory(id)).pipe(
        map(
          (response) =>
            ({
              type: 'REPORTS_HISTORY_FETCH_SUCCESS',
              payload: { historyList: response },
            } as ReportsHistoryFetchSuccessAction),
        ),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<ReportsHistoryFetchFailureAction>({ type: 'REPORTS_HISTORY_FETCH_FAILURE' })),
      ),
    ),
  )

const reportsHistorySuccessEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReportsHistoryFetchSuccessAction>('REPORTS_HISTORY_FETCH_SUCCESS'),
    switchMap(() => of<ReportsArticlesAction>({ type: 'REPORTS_ARTICLES', payload: { groupidentical: true } })),
  )

const reportsCreateEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ReportsCreateAction>('REPORTS_CREATE'),
    switchMap(({ payload }) => {
      const state = state$.value
      const { type, id } = getSource(state)
      const deletedArticles = getDeletedArticles(state)
      const locale = getOpointLocale(state)
      const { groupidentical, updatedSoMe } = payload
      const groupingHiddenAndDisabled = !getSimilarArticlesVisible(state)
      const updateSoMe = getUpdateSoMe(state)

      if (!updatedSoMe && updateSoMe) {
        return EMPTY
      }

      let subparams
      switch (type) {
        case 'tag':
          subparams = useReportHistory(state)
            ? {
                tagId: id,
                stimestamps: getCheckedTimestamps(state),
              }
            : {
                tagId: id,
                oldest: getUnixTime(new Date(getStartDate(state))),
                newest: getUnixTime(new Date(getEndDate(state))),
                expressions: getSearchItems(state),
              }
          break
        case 'current_search':
          subparams = {
            sortedArticles: getSortedIDs(state),
            oldest: getUnixTime(new Date(getStartDate(state))),
            newest: getUnixTime(new Date(getEndDate(state))),
            expressions: getSearchItems(state),
          }
          break
        case 'profile':
          subparams = {
            sortedArticles: getSortedIDs(state),
            oldest: getUnixTime(new Date(getStartDate(state))),
            newest: getUnixTime(new Date(getEndDate(state))),
            expressions: getSearchItems(state),
          }
          break
        case 'selected_articles':
          subparams = {
            articles: groupidentical ? getSortedIDs(state) : getAllSortedIDs(state),
          }
          break
        default:
          subparams = {}
      }

      const activeTemplate = getTemplate({ reports: { template: state.reports.template } })
      const activeTemplateDetail = getTemplateModules(activeTemplate)(state)
      const includeOption = activeTemplateDetail?.MODULE_MAIN?.widgets?.find((item) => item.name === 'RT_INC_IDENTICAL')

      const isGroupingEnabled = groupidentical
      const isXlsTemplateCondition = !getIsXLSTemplate(state) && isGroupingEnabled
      const willIncludeOption = includeOption ? !(includeOption.value === '2') : isXlsTemplateCondition

      const params = {
        templateId: getTemplate({ reports: { template: state.reports.template } }),
        title: getTitle(state),
        locale,
        params: {
          ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
          excludearticles: deletedArticles,
          groupidentical: groupingHiddenAndDisabled ? false : willIncludeOption,
        },
        preface: '',
        footer: '',
      }

      const { html: prefaceHTML, text: prefaceText } = getPreface(state)
      const applyPreface = prefaceHTML || prefaceText
      applyPreface && (params.preface = `${prefaceHTML ? prefaceHTML : ''} ${prefaceText ? prefaceText : ''}`)

      const { html: footerHTML, text: footerText } = getFooter(state)
      const applyFooter = footerHTML || footerText
      applyFooter && (params.footer = `${footerHTML ? footerHTML : ''} ${footerText ? footerText : ''}`)

      const generateReport$ = defer(() => createReport({ ...params, ...subparams }))

      const generateReportTakingTooLong$ = of<ReportsCreateIsTakingTooLongAction>({
        type: 'REPORTS_CREATE_IS_TAKING_TOO_LONG',
      }).pipe(
        delay(8000),
        takeUntil(action$.pipe(ofType<AppActions, ReportsCreateSuccessAction>('REPORTS_CREATE_SUCCESS'))),
      )

      return merge(
        generateReport$.pipe(
          switchMap((reportObject) => {
            const reportId = reportObject?.report_id

            if (reportObject?.status !== 202 || !reportId) {
              return of<ReportsCreateFailureAction>({ type: 'REPORTS_CREATE_FAILURE' })
            }

            const actions: Array<any> = [
              { type: 'REPORTS_UPDATE_REPORT_ID', payload: { reportId } } as ReportsUpdateReportIdAction,
            ]

            return of(...actions)
          }),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<ReportsCreateFailureAction>({ type: 'REPORTS_CREATE_FAILURE' })),
        ),
        generateReportTakingTooLong$,
      )
    }),
  )

const reportCheckingStatusEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ReportsUpdateReportIdAction>('REPORTS_UPDATE_REPORT_ID'),
    switchMap(({ payload }) => {
      const checkStatus$ = defer(() => checkReportStatus({ id: payload.reportId }))

      return interval(10000).pipe(
        switchMap(() => {
          if (!isReportModalOpen(state$.value)) {
            return of<ReportsStopStatusCheckingAction>({ type: 'REPORTS_STOP_STATUS_CHECKING' })
          }

          return checkStatus$.pipe(
            switchMap((reportObject) => {
              if (reportObject.status === 1) {
                return of<ReportsCreateSuccessAction>({
                  type: 'REPORTS_CREATE_SUCCESS',
                  payload: { reportObject },
                })
              } else if (reportObject.status === -1) {
                return of<ReportsCreateFailureAction>({ type: 'REPORTS_CREATE_FAILURE' })
              } else {
                return of()
              }
            }),
          )
        }),
        takeUntil(
          action$.pipe(
            ofType<
              AppActions,
              ReportsCreateSuccessAction | ReportsCreateFailureAction | ReportsStopStatusCheckingAction
            >('REPORTS_CREATE_SUCCESS', 'REPORTS_CREATE_FAILURE', 'REPORTS_STOP_STATUS_CHECKING'),
          ),
        ),
      )
    }),
  )

const reportsCreateFinishEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, NotificationSocketSuccessAction>('NOTIFICATIONS_SOCKET_SUCCESS'),
    switchMap(({ payload }: { payload: Notification }) => {
      const state = state$.value
      const reportId = getReportId(state)
      // @ts-expect-error: Muted so we could enable TS strict mode
      const reportObject: ReportObject = payload.object?.value
      const isNotificationUpdate = payload.type === 'updated'
      const isReportNotification = payload.object?.type === 'report'
      const isCurrentTab = reportObject?.id === reportId

      if (!isReportNotification || !isNotificationUpdate || !isCurrentTab) {
        return of()
      }

      if (reportObject.status === -1) {
        return of<ReportsCreateFailureAction>({ type: 'REPORTS_CREATE_FAILURE' })
      }

      return of<ReportsCreateSuccessAction>({
        type: 'REPORTS_CREATE_SUCCESS',
        payload: { reportObject },
      })
    }),
  )

const shareReportEpic = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, ShareReportAction>('SHARE_REPORT'),
    switchMap(({ payload: { source, notification } }) => {
      const state = state$.value

      let reportObject
      switch (source) {
        case 'REPORTS_MODAL': {
          reportObject =
            getReportObject(state) ||
            getReportHistoryObject({ reportsHistory: { reportObject: state.reportsHistory.reportObject } })
          break
        }
        default: {
          // @ts-expect-error: Muted so we could enable TS strict mode
          reportObject = notification ? notification.object.value : {}
          break
        }
      }

      const id = [reportObject.id]
      const message = getShareData(state).shareReportMessage
      const shareAttachment = getShareData(state).attachment
      const recipients = getReportContacts(state)?.map((contact) => {
        const { type, entity } = contact

        return {
          type,
          id: entity.id,
          ...('value' in entity && { value: entity.value }),
        }
      })

      // Checks whether user chose at least one recipient
      if (!recipients.length) {
        return concat(
          of<ReportsValidShareFailureAction>({ type: 'REPORTS_VALID_SHARE_FAILURE' }),
          of<ShareReportUpdateStepAction>({ type: 'SHARE_REPORT_UPDATE_STEP', payload: { stepNumber: 2 } }),
        )
      }

      const allowedDomains = getAllowedDomains(state)
      const anyDomainRestrictions = !!allowedDomains

      // Checks whether user only chose allowed email addresses
      if (anyDomainRestrictions && includesDisallowedEmailRecipients(recipients, allowedDomains)) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('You are only allowed to send to recipients inside your organisation'),
          },
        })
      }

      return from(
        shareReport({
          id,
          message,
          // @ts-expect-error: Muted so we could enable TS strict mode
          recipients,
          shareAttachment,
        }),
      ).pipe(
        switchMap(() =>
          concat(
            of<ReportsShareSuccessAction>({ type: 'REPORTS_SHARE_SUCCESS' }),
            of<ShareReportUpdateStepAction>({
              type: 'SHARE_REPORT_UPDATE_STEP',
              payload: { stepNumber: 4 },
            }),
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          concat(
            of<ReportsShareFailureAction>({ type: 'REPORTS_SHARE_FAILURE' }),
            of<ShareReportUpdateStepAction>({
              type: 'SHARE_REPORT_UPDATE_STEP',
              payload: { stepNumber: 5 },
            }),
          ),
        ),
      )
    }),
  )

const deleteReportEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteReportAction>('DELETE_REPORT'),
    switchMap(({ payload }) => {
      const { id } = payload

      return from(deleteReport({ id: [id] })).pipe(
        map(() => {
          return { type: 'DELETE_REPORT_SUCCESS', payload: { id } } as DeleteReportSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<DeleteReportFailureAction>({ type: 'DELETE_REPORT_FAILURE' })),
      )
    }),
  )

const reportsResetActionEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ReportsModalCloseAction>('REPORTS_MODAL_CLOSE'),
    switchMap(() => {
      const state = state$.value
      const updateSoMe = getUpdateSoMe(state)

      if (updateSoMe) {
        return EMPTY
      }

      return of<ReportsResetAction>({
        type: 'REPORTS_RESET',
        payload: {
          soft: state$.value.reports.step <= REPORT_STEP_CREATE_REPORT,
          // preserve some metadata (soft reset) if modal closed before `generate report`
        },
      }).pipe(delay(500))
    }),
  )

const reportsUpdatedSoMeResetEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, ReportsModalCloseAction>('REPORTS_MODAL_CLOSE'), take(1)),
    action$.pipe(ofType<AppActions, UpdatedSoMeMetaDataSuccessAction>('UPDATED_SOME_META_DATA_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(() => {
      // * Fixes bug where modal resets before closing
      // https://infomediacorp.atlassian.net/browse/FE-10472
      if (isReportModalOpen(state$.value)) {
        return of()
      }

      return of<ReportsResetAction>({
        type: 'REPORTS_RESET',
        payload: {
          soft: state$.value.reports.step <= REPORT_STEP_CREATE_REPORT,
          // preserve some metadata (soft reset) if modal closed before `generate report`
        },
      })
    }),
    delay(500),
  )

export default [
  deleteReportEpic,
  reportLoadInitialEpic,
  reportUpdateSoMeMetadata,
  reportsCreateEpic,
  reportsCreateFinishEpic,
  reportsHistoryEpic,
  reportsHistorySuccessEpic,
  reportsLoadEpic,
  reportsOpenModalEpic,
  reportsResetActionEpic,
  reportsSourceEpic,
  reportsTitleEpic,
  reportsUpdatedSoMeResetEpic,
  shareReportEpic,
  reportsHistorySuccessEpic,
  reportUpdateSoMeMetadata,
  reportCheckingStatusEpic,
]
