import { compose, contains, equals, groupBy, keys, not, prop, reject, sortBy } from 'ramda'
import { AlertNextPreview } from '../alerts/types'
import config from '../common/config'
import type { NewTemplate, Template, TemplateModule, TemplateSettings, Widget } from '../flow'
import { handleErrors } from '../common'

/**
 * Constants
 */

export const TEMPLATE_TYPES = {
  PDF: 1,
  HTML: 3,
  XLS: 7,
  XML: 8,
  DOC: 9,
  PPT: 10,
  RSS: 11,
  JSON: 12,
}

export const TEMPLATE_TYPES_ONLY_FOR_DOWNLOAD = {
  XLS: 7,
  DOC: 9,
  PPT: 10,
}

export const EXCLUDED_TEMPLATES = [17]

/**
 * Functions
 */

export const getTemplates = async (locale = 'en-GB') => {
  const url = config.url.api('/templates/')
  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, { ...requestHeaders, method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getTemplate = async (templateId: number, locale = 'en-GB') => {
  const url = config.url.api(`/templates/${templateId}/`)
  const requestHeaders = await config.request.getRequestHeaders()
  requestHeaders.headers['accept-language'] = locale

  const request = new Request(url, { ...requestHeaders, method: 'GET' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getTemplatePreview = async (templateId: number): Promise<AlertNextPreview> => {
  const url = config.url.api(`/templates/${templateId}/preview/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'POST' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const createTemplate = async (template: NewTemplate) => {
  const url = config.url.api('/templates/')
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(template),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const saveTemplate = async (templateSettings: TemplateSettings, templateId: number) => {
  const url = config.url.api(`/templates/${templateId}/`)
  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'PUT',
    body: JSON.stringify(templateSettings),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const deleteTemplate = async (templateId: number) => {
  const url = config.url.api(`/templates/${templateId}/`)
  const request = new Request(url, { ...(await config.request.getRequestHeaders()), method: 'DELETE' })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.text())
}

/**
 * Gets keys of groupedTemplates object as an array of keys with html format key on the first place
 * @param groupedTemplates is an object containing format keys (number)
 *   and their templates (arrays of objects)
 * @returns format keys array with html format on the first place
 */
// @ts-expect-error: Muted so we could enable TS strict mode
export function getGroupedTemplatesKeys(groupedTemplates: { [string]: Array<Template> }): Array<string> {
  return sortBy(compose(not, equals(TEMPLATE_TYPES.HTML.toString())))(keys(groupedTemplates)).filter((type) => {
    // RSS is not supported by the back-end
    return type !== TEMPLATE_TYPES.RSS.toString()
  })
}

/**
 * Rejects templates that are excluded (e.g. template 17 which still uses old generating script)
 * @param templates is an array containing template objects
 * @returns filtered templates
 */
export function filterExcludedTemplates(templates: Array<Template>): Array<Template> {
  // @ts-expect-error: Muted so we could enable TS strict mode
  return reject((template) => contains(template.id, EXCLUDED_TEMPLATES), templates)
}

/**
 * Returns object which contains all properties from objectB that are different from objectA
 * @param objectA original object
 * @param objectB updated object
 * @param objectC everything in this object must be in the final object
 * @returns new object
 */
export function objectDiff(objectA: any, objectB: any, objectC: any) {
  const keysB = Object.keys(objectB)
  const keysBLength = keysB.length
  const diff = {}
  let key

  for (let i = 0; i < keysBLength; i++) {
    key = keysB[i]

    if (objectC && objectC[key] !== undefined) {
      diff[key] = objectB[key]
    } else if (objectA[key] !== objectB[key]) {
      diff[key] = objectB[key]
    }
  }

  return diff
}

export function getUpdatedModule(module: TemplateModule, sortableModuleWidgets: Array<Widget>): TemplateModule {
  const sortableModuleWidgetsByName = groupBy(prop('name'), sortableModuleWidgets)

  const widgets = module.widgets.map((widget: Widget) => ({
    ...widget,
    sortIndex: sortableModuleWidgetsByName[widget.name][0].sortIndex,
  }))

  // @ts-expect-error: Muted so we could enable TS strict mode
  const sortedWidgets = widgets.sort((a, b) => a.sortIndex - b.sortIndex)

  return {
    ...module,
    widgets: sortedWidgets,
  }
}
