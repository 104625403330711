import { DocumentSignature24Regular } from '@fluentui/react-icons'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const AgreementsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionHeaderLink
        label={t('Agreements')}
        isExpanded={Boolean(isExpanded)}
        ref={ref}
        to="/agreements"
        section={NavigationSectionKey.AGREEMENTS}
        {...props}
      >
        <DocumentSignature24Regular className="text-sea. w-7 shrink-0" />
      </NavigationSectionHeaderLink>
    </NavigationSection>
  ),
)
AgreementsNavigationSection.displayName = 'AgreementsNavigationSection'
